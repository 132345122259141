export const SET_AREA_VALIDATION = "SET_AREA_VALIDATION";
export const SET_INTERSECTION_VALIDATION = "SET_INTERSECTION_VALIDATION";

export function setAreaValidation(bool) {
  return {
    type: SET_AREA_VALIDATION,
    isInsideBoundary: bool,
  }
}

export function setIntersectionValidation(bool) {
  return {
    type: SET_INTERSECTION_VALIDATION,
    hasIntersection: bool,
  }
} 
