import { useState } from "react";
import { useTranslation } from "react-i18next";
import Menu from "@mui/material/Menu";
import IconSelector from "../../IconSelector";
import LinesEllipsis from "react-lines-ellipsis";
import * as S from "./SelectLocales.styled";

function SelectLocales({
  locationsOptions,
  onSelectedOptionsChange,
  onSelectAll,
  disable,
}) {
  const { t } = useTranslation();
  const [menuRef, setMenuRef] = useState(null);

  const onOpenMenu = (event) => {
    setMenuRef(event.currentTarget);
  };

  const onCloseMenu = () => {
    setMenuRef(null);
  };

  const handleOptionClick = (option) => {
    onSelectedOptionsChange(option);
    onCloseMenu(); // Close the menu after selecting an option
  };

  return (
    <div>
      <S.StyledButton onClick={onOpenMenu} disabled={disable}>
        <IconSelector svg="LocationPin" size={20} />
        {t("headerTableBtn.locales")}
        <IconSelector svg="DownArrow" size={20} />
      </S.StyledButton>
      <Menu
        anchorEl={menuRef}
        open={Boolean(menuRef)}
        onClose={onCloseMenu}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
        PaperProps={{
          sx: {
            maxHeight: "148px",
            "&::-webkit-scrollbar": {
              display: "none", // Hide scrollbar for Chrome, Safari, Opera
            },
            "-ms-overflow-style": "none", // Hide scrollbar for IE and Edge
            "scrollbar-width": "none", // Hide scrollbar for Firefox
          },
        }}
      >
        <S.StyledMenuItem
          key="all"
          onClick={() => {
            onSelectAll();
            onCloseMenu();
          }}
        >
          {t("rainReport.rainHeader.all")}
        </S.StyledMenuItem>
        {locationsOptions &&
          locationsOptions.length !== 0 &&
          locationsOptions.map((option) => (
            <S.StyledMenuItem
              key={option.local_id}
              onClick={() => handleOptionClick(option)}
            >
              <LinesEllipsis
                text={option.local_name}
                maxLine={"2"}
                ellipsis={"..."}
                trimRight={true}
                basedOn={"letters"}
              />
            </S.StyledMenuItem>
          ))}
      </Menu>
    </div>
  );
}

export default SelectLocales;
