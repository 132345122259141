import TextInput from '../../form_elements/TextInput'
import SelectInput from '../../form_elements/SelectInput'

function AccountData({props}) {
	return (
		<>
			<div className='input-controls'>
				<TextInput
					params={{										
						id: 'email',
						editDisabled: true,
						validations: [
							{
								type: 'required',
							},
							{
								type: 'email'
							}
						],
						formType: 'users',
						formProps: props.formProps,
						isEditing: props.itemId,
					}}
				/>
			</div>
			<div className='input-controls'>
				<SelectInput
					params={{										
						id: 'profile_id',
						optionsUrl: 'api/cittua/users/list-profiles',
						editDisabled: false,
						validations: [
							{
								type: 'required',
							}
						],
						formType: 'users',
						formProps: props.formProps,
						isEditing: props.itemId,
						multiple: true,
					}}
				/>
			</div>
		</>
	)
}

export default AccountData