import { useCookies } from 'react-cookie'
import {  Navigate } from "react-router-dom"
import Dashboard from './Dashboard'

function PrivateRoutes() {
	const [cookies] = useCookies(['user'])

	if (cookies.user !== undefined) {
		if ((cookies.user.msg && cookies.user.msg === 'Token has expired') || cookies.tenant === undefined) {
			return <Navigate to='/login' replace/>
		} 

		return <Dashboard/>

	} 
		return <Navigate to='/login' replace/>
}

export default PrivateRoutes