import React, { useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Popper, ClickAwayListener, Paper, Box } from "@mui/material";
import { selectCalendarLocale } from "../../../i18n/i18n";
import { registerLocale } from "react-datepicker";
import enUS from "date-fns/locale/en-US";
import pt from "date-fns/locale/pt";
import IconSelector from "../../IconSelector";
import * as S from "./DateRangeCalendar.styled";

export const DateRangeCalendar = ({
  minDate,
  maxDate,
  calendarDate,
  setCalendarDate,
  disable,
}) => {
  const { t } = useTranslation();

  registerLocale("pt", pt);
  registerLocale("en-US", enUS);

  const [open, setOpen] = useState(false);

  const anchorRef = useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleDateChange = (item) => {
    const newDates = [item.selection];
    setCalendarDate(newDates);
    const startDate = newDates[0]?.startDate;
    const endDate = newDates[0]?.endDate;
    if (startDate && endDate && startDate.getTime() !== endDate.getTime()) {
      setOpen(false);
    }
  };

  return (
    <div>
      <S.StyledButton ref={anchorRef} onClick={handleToggle} disabled={disable}>
        <IconSelector svg="CalendarPurple" size={20} />
        {t("rainReport.rainHeader.period")}
        <IconSelector svg="DownArrow" size={20} />
      </S.StyledButton>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        placement="bottom-start"
        disablePortal
        style={{ zIndex: 1300 }}
      >
        <ClickAwayListener onClickAway={handleClose}>
          <Paper elevation={3}>
            <Box p={2}>
              <S.StyledDateRangePicker
                locale={selectCalendarLocale()}
                onChange={handleDateChange}
                showSelectionPreview={false}
                moveRangeOnFirstSelection={false}
                months={2}
                ranges={calendarDate}
                direction="horizontal"
                inputRanges={[]}
                staticRanges={[]}
                minDate={minDate}
                maxDate={maxDate}
              />
            </Box>
          </Paper>
        </ClickAwayListener>
      </Popper>
    </div>
  );
};
