import { useTranslation } from "react-i18next";
import { useEffect } from "react";

import { TextSelectorPt } from "./TextSelector";

function TermsAndPrivacyPt({ type }) {
  let title = "Termos de Uso";
  if (type === "privacy") {
    title = "Políticas de Privacidade";
  }

  useEffect(() => {
    document.title = title;
  });
  return (
    <div className="c-terms-and-privacy">
      <h1 className="terms-and-privacy__title">{title}</h1>
      <TextSelectorPt type={type} />
    </div>
  );
}

export default TermsAndPrivacyPt;
