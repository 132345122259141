import Button from '@mui/material/Button'
import { useTranslation } from 'react-i18next'

function ExportTableMenu({params}) {
	const { t } = useTranslation()
	return (
		<div>
			<Button classes={{root: 'btn-secondary btn-secondary--medium btn-table-export search-table-btn'}} onClick={params.onExportTable}>
				{t('headerTableBtn.export')}
			</Button>
		</div>
	)
}

export default ExportTableMenu