import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";

import IconSelector from "../IconSelector";
import Modal from "../Modal";
import BtnLoadingText from "../BtnLoadingText";

const API_URLS = {
	CREATE_USER: "/credential/create-user",
	VERIFY_PIN: "/credential/verify-pin",
	ACTIVATE_USER: "/credential/activate-user",
};

async function apiRequest(url, method, data = {}) {
	try {
		const response = await fetch(`${process.env.REACT_APP_SERVICE_URL}/api${url}`, {
			method,
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify(data),
		});
		if (!response.ok) {
			const errorData = await response.json();
			throw new Error(errorData.message || "Erro ao processar requisiÃ§Ã£o");
		}
		return response.json();
	} catch (error) {
		console.error(`Erro na requisiÃ§Ã£o para ${url}:`, error.message);
		alert(error.message);
		throw error;
	}
}

const ApiService = {
    createUser: (username, name, email) =>
        apiRequest(API_URLS.CREATE_USER, "POST", { username, name, email }),
    verifyPin: (username, pin) =>
        apiRequest(API_URLS.VERIFY_PIN, "POST", { username, pin }),
    activateUser: (username, password, pin, name, email) =>
        apiRequest(API_URLS.ACTIVATE_USER, "POST", {
            username,
            name,
            email,
            password,
            pin,
        }),
};


const initialValues = {
	name: "",
	email: "",
	country: "+55",
	cellPhone: "",
	password: "",
	confirmPassword: "",
	termsAndPrivacy: false,
};

function CreateAccount() {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const [passwordVisibility, setPasswordVisibility] = useState(false);
	const [confirmPasswordVisibility, setConfirmPasswordVisibility] =
		useState(false);
	const [textMode, setTextMode] = useState("");
	const [openTextModal, setOpenTextModal] = useState(false);
	const [isSMSModalOpen, setIsSMSModalOpen] = useState(false);
	const [timerSMS, setTimerSMS] = useState(90);
	const [isTimerStarted, setIsTimerStarted] = useState(false);
	const [createError, setCreateError] = useState(false);
	const [countryCodes, setCountryCodes] = useState([]);
	const [phoneNumber, setPhoneNumber] = useState("");
	const [code, setCode] = useState(Array(6).fill(""));
	const [password, setPassword] = useState("");
	const [formData, setFormData] = useState(initialValues);

	useEffect(() => {
		document.title = t("create-account.pageTitle");
	}, [t]);

	useEffect(() => {
		fetch("https://restcountries.com/v3.1/all")
			.then((response) => response.json())
			.then((data) => {
				const countryData = data
					.filter((country) => country.idd?.root)
					.map((country) => ({
						flag: country.flags.png || "",
						code: `${country.idd.root || "+5"}${country.idd.suffixes?.[0] || "5"
							}`,
					}));
				setCountryCodes(countryData);
			})
			.catch((error) => console.error("Error fetching country codes:", error));
	}, []);

	useEffect(() => {
		if (isSMSModalOpen && timerSMS > 0 && isTimerStarted) {
			const interval = setInterval(() => {
				setTimerSMS((prev) => {
					if (prev <= 1) {
						clearInterval(interval);
						setIsTimerStarted(false);
						return 0;
					}
					return prev - 1;
				});
			}, 1000);
			return () => clearInterval(interval);
		}
	}, [timerSMS, isSMSModalOpen, isTimerStarted]);

	const handleConfirmCode = async (phoneNumber, fullCode, password, name, email) => {
		try {
		  if (!password) {
			throw new Error("Senha não pode estar vazia.");
		  }
	  
		  if (fullCode.length < 6) {
			throw new Error("O PIN deve conter 6 dígitos.");
		  }
	  
		  await ApiService.verifyPin(phoneNumber, fullCode);
		  await ApiService.activateUser(phoneNumber, password, fullCode, name, email);
	  
		  
		  await localStorage.setItem(
			"userInfo",
			JSON.stringify({
			  name,
			  email,
			  phone: phoneNumber,
			})
		  );
	  
		  alert("Usuário cadastrado com sucesso!");
		  navigate("/user-area/info-locations");
		} catch (error) {
		  console.error("Erro na validação ou ativação do usuário:", error.message);
		}
	};	  

	const handleActivateUser = async (password) => {
		try {
			const fullCode = code.join("");
			const response = await ApiService.activateUser(
				phoneNumber,
				password,
				fullCode
			);
			navigate("/success-page");
		} catch (error) {
			console.error("Erro ao ativar usuÃ¡rio:", error.message);
		}
	};

	const dataValidation = (values) => {
		const errors = {};
		if (!values.name) errors.name = t("common.inputErrors.fieldRequired");
		if (!values.email) {
			errors.email = t("common.inputErrors.fieldRequired");
		} else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
			errors.email = t("common.inputErrors.invalidUsername");
		}
		if (!values.cellPhone) {
			errors.cellPhone = t("common.inputErrors.fieldRequired");
		} else if (!/^\(?\d{2}\)?[\s-]?\d{4,5}[-]?\d{4}$/.test(values.cellPhone)) {
			errors.cellPhone = t("common.inputErrors.cellPhoneError");
		}
		if (!values.password)
			errors.password = t("common.inputErrors.fieldRequired");
		if (!values.confirmPassword) {
			errors.confirmPassword = t("common.inputErrors.fieldRequired");
		} else if (values.password !== values.confirmPassword) {
			errors.confirmPassword = t("common.inputErrors.confirmPassInvalid");
		}
		if (!values.termsAndPrivacy)
			errors.termsAndPrivacy = t("common.inputErrors.acceptTerms");
		return errors;
	};

	const sendSMS = async (phoneNumber, name, email) => {
		try {
			const response = await ApiService.createUser(phoneNumber, name, email);
			if (response) {
				clearCode();
			} else {
				throw new Error("Falha no envio de SMS ou criaÃ§Ã£o de usuÃ¡rio.");
			}
		} catch (error) {
			console.error("Erro ao enviar SMS:", error.message);
			throw error;
		}
	};

	const clearCode = () => {
		setCode(Array(6).fill(""));
	};

	const onOpenTextModal = (mode) => {
		setTextMode(mode);
		setOpenTextModal(true);
	};

	const onCloseTextModal = () => {
		setOpenTextModal(false);
	};

	const submitData = async (values, actions) => {
		try {
			setFormData(values);
			const fullPhoneNumber = `${values.country}${values.cellPhone}`;
			setPhoneNumber(fullPhoneNumber);
			setPassword(values.password);
			await sendSMS(fullPhoneNumber, values.name, values.email);
			setIsSMSModalOpen(true);
			actions.setSubmitting(false);
		} catch (error) {
			console.error("Erro ao enviar SMS ou criar o usuário:", error.message);
			actions.setSubmitting(false);
			setCreateError(true);
		}
	};	

	return (
		<div className="">
			<div className="card-internal">
				<h1 className="form-title">{t("create-account.formTitle")}</h1>

				<div className="form-text-type1">
					{t("create-account.guidelines.part1")}
				</div>
				<div className="form-text-type1">
					{t("create-account.guidelines.part2")}
				</div>
				<div className="form-text-type1">
					{t("create-account.guidelines.part3")}
				</div>
				<div className="form-text-type2">
					{t("create-account.guidelines.part4")}
				</div>
				<div className="form-text-type1">
					{t("create-account.guidelines.part5")}
				</div>

				<Formik
					initialValues={initialValues}
					validate={(values) => dataValidation(values)}
					onSubmit={(values, actions) => submitData(values, actions)}
				>
					{({
						values,
						errors,
						touched,
						isSubmitting,
						handleSubmit,
						handleBlur,
						handleChange,
						setFieldValue,
					}) => (
						<form className="login-form internal-form" onSubmit={handleSubmit}>
							{/* Nome */}
							<div className="c-input">
								<label className="label" htmlFor="name">
									{t("create-account.fields.name")}
								</label>
								<input
									id="name"
									name="name"
									onChange={handleChange}
									onBlur={handleBlur}
									className={
										errors.name && touched.name ? "input input--error" : "input"
									}
									placeholder={t("create-account.placeholder.email")}
									disabled={isSubmitting}
								/>
								<div
									className={
										errors.name && touched.name
											? "c-error-text"
											: "c-error-text c-error-text--hidden"
									}
								>
									<IconSelector
										svg="ErrorWarningLine"
										classname="icon--warning"
									/>
									<span className="error-text">{errors.name}</span>
								</div>
							</div>

							{/* E-mail */}
							<div className="c-input">
								<label className="label" htmlFor="email">
									{t("create-account.fields.email")}
								</label>
								<input
									id="email"
									name="email"
									onChange={handleChange}
									onBlur={handleBlur}
									className={
										errors.email && touched.email
											? "input input--error"
											: "input"
									}
									placeholder={t("create-account.placeholder.email")}
									disabled={isSubmitting}
								/>
								<div
									className={
										errors.email && touched.email
											? "c-error-text"
											: "c-error-text c-error-text--hidden"
									}
								>
									<IconSelector
										svg="ErrorWarningLine"
										classname="icon--warning"
									/>
									<span className="error-text">{errors.email}</span>
								</div>
							</div>

							{/* Celular */}
							<div className="c-input">
								<label className="label" htmlFor="cellPhone">
									{t("create-account.fields.cellPhone")}
								</label>
								<div className="phone-input-group">
									<Select
										options={countryCodes}
										value={
											countryCodes.find(
												(country) => country.code === values.country
											) ||
											countryCodes.find((country) => country.code === "+55")
										}
										onChange={(selectedOption) =>
											setFieldValue("country", selectedOption.code)
										}
										classNamePrefix="react-select"
										isSearchable
										getOptionLabel={(country) => (
											<div className="select-option">
												<img
													src={country.flag}
													className="country-flag"
													alt="flag"
												/>
												<span>{country.code}</span>
											</div>
										)}
										components={{
											SingleValue: ({ data }) => (
												<div className="select-single-value">
													<img
														src={data.flag}
														className="country-flag"
														alt="flag"
													/>
													<span>{data.code}</span>
												</div>
											),
											Option: ({ data, innerRef, innerProps }) => (
												<div
													ref={innerRef}
													{...innerProps}
													className="select-option"
												>
													<img
														src={data.flag}
														className="country-flag"
														alt="flag"
													/>
													<span>{data.code}</span>
												</div>
											),
										}}
									/>
									<input
										id="cellPhone"
										name="cellPhone"
										onChange={handleChange}
										onBlur={handleBlur}
										className={
											errors.cellPhone && touched.cellPhone
												? "input input--error"
												: "input"
										}
										placeholder={t("create-account.placeholder.cellPhone")}
										disabled={isSubmitting}
									/>
								</div>
								<div
									className={
										errors.cellPhone && touched.cellPhone
											? "c-error-text"
											: "c-error-text c-error-text--hidden"
									}
								>
									<IconSelector
										svg="ErrorWarningLine"
										classname="icon--warning"
									/>
									<span className="error-text">{errors.cellPhone}</span>
								</div>
							</div>

							{/* Senha */}
							<div className="c-input">
								<label className="label" htmlFor="password">
									{t("create-account.fields.password")}
								</label>
								<input
									id="password"
									name="password"
									type={passwordVisibility ? "text" : "password"}
									onChange={handleChange}
									onBlur={handleBlur}
									value={values.password}
									className={
										errors.password && touched.password
											? "input input--password input--error"
											: "input input--password"
									}
									placeholder={t("create-account.placeholder.password")}
									disabled={isSubmitting}
								/>
								<button
									className="btn-icon"
									type="button"
									onClick={() => setPasswordVisibility(!passwordVisibility)}
								>
									<IconSelector
										svg={passwordVisibility ? "EyeLine" : "EyeCloseLine"}
										classname="icon--password"
									/>
								</button>
								<div
									className={
										errors.password && touched.password
											? "c-error-text"
											: "c-error-text c-error-text--hidden"
									}
								>
									<IconSelector
										svg="ErrorWarningLine"
										classname="icon--warning"
									/>
									<span className="error-text">{errors.password}</span>
								</div>
							</div>

							{/* Confirma senha */}
							<div className="c-input">
								<label className="label" htmlFor="confirmPassword">
									{t("create-account.fields.confirmPassword")}
								</label>
								<input
									id="confirmPassword"
									name="confirmPassword"
									type={confirmPasswordVisibility ? "text" : "password"}
									onChange={handleChange}
									onBlur={handleBlur}
									value={values.confirmPassword}
									className={
										errors.confirmPassword && touched.confirmPassword
											? "input input--confirmPassword input--error"
											: "input input--confirmPassword"
									}
									placeholder={t("create-account.placeholder.confirmPassword")}
									disabled={isSubmitting}
								/>
								<button
									className="btn-icon"
									type="button"
									onClick={() =>
										setConfirmPasswordVisibility(!confirmPasswordVisibility)
									}
								>
									<IconSelector
										svg={confirmPasswordVisibility ? "EyeLine" : "EyeCloseLine"}
										classname="icon--confirmPassword"
									/>
								</button>
								<div
									className={
										errors.confirmPassword && touched.confirmPassword
											? "c-error-text"
											: "c-error-text c-error-text--hidden"
									}
								>
									<IconSelector
										svg="ErrorWarningLine"
										classname="icon--warning"
									/>
									<span className="error-text">{errors.confirmPassword}</span>
								</div>
							</div>

							{/* Termos de uso */}
							<div className="login-checkbox terms-internal-pages">
								<Checkbox
									name="termsAndPrivacy"
									checked={values.termsAndPrivacy}
									onChange={handleChange}
									onBlur={handleBlur}
									icon={
										<IconSelector
											svg={"Checkbox"}
											classname={"icon--checkbox"}
										/>
									}
									checkedIcon={
										<IconSelector
											svg={"CheckedCheckbox"}
											classname={"icon--checkbox"}
										/>
									}
									classes={{
										root: "login-checkbox",
									}}
									disabled={isSubmitting}
								/>
								<label htmlFor="termsAndPrivacy" className="checkbox-label">
									{t("create-account.termsAndPrivacy.part1")}&nbsp;
									<button
										type="button"
										className="login-btn-link btn-link"
										onClick={() => onOpenTextModal("terms")}
									>
										{t("create-account.termsAndPrivacy.terms")}
									</button>
									&nbsp;{t("create-account.termsAndPrivacy.part2")}&nbsp;
									<button
										type="button"
										className="login-btn-link btn-link"
										onClick={() => onOpenTextModal("privacy")}
									>
										{t("create-account.termsAndPrivacy.privacy")}
									</button>
									&nbsp;{t("create-account.termsAndPrivacy.part3")}
								</label>
							</div>
							<div
								className={
									errors.termsAndPrivacy && touched.termsAndPrivacy
										? "c-error-text"
										: "c-error-text c-error-text--hidden"
								}
							>
								<IconSelector
									svg={"ErrorWarningLine"}
									classname={"icon--warning"}
								/>
								<span className="error-text">{errors.termsAndPrivacy}</span>
							</div>

							{/* Submit */}
							<Button
								classes={{
									root: `create-account-btn btn-primary btn-primary--large-full-width ${
									!isSubmitting && values.termsAndPrivacy ? "enabled" : ""
									}`,
								}}
								type="submit"
								disabled={isSubmitting || !values.termsAndPrivacy}
								>
								{isSubmitting ? <BtnLoadingText /> : t("create-account.btn.submit")}
							</Button>
						</form>
					)}
				</Formik>
			</div>

			<Modal
				type="scrollText"
				params={{
					openModal: openTextModal,
					closeModal: onCloseTextModal,
					changeContentFlag: textMode,
				}}
			/>
			<Modal
				type="smsVerification"
				params={{
					openModal: isSMSModalOpen,
					closeModal: () => setIsSMSModalOpen(false),
					phoneNumber: phoneNumber,
					code: code,
					onInputChange: (value, index) => {
						const newCode = [...code];
						newCode[index] = value.replace(/\D/, "");
						setCode(newCode);
					},
					timer: timerSMS,
					startTimerSMS: () => {
						if (!isTimerStarted) {
							setIsTimerStarted(true);
							setTimerSMS(90);
							const interval = setInterval(() => {
								setTimerSMS((prev) => {
									if (prev <= 1) {
										clearInterval(interval);
										setIsTimerStarted(false);
										return 0;
									}
									return prev - 1;
								});
							}, 1000);
						}
					},
					clearCode: () => {
						const clearedCode = Array(6).fill("");
						setCode(clearedCode);
					},
					isTimerStarted: isTimerStarted,
					onResend: () => {
						const clearedCode = Array(6).fill("");
						setCode(clearedCode);
						sendSMS(phoneNumber);
					},
					onConfirm: async () => {
						const fullCode = code.join("");

						if (fullCode.length === 6) {
							await handleConfirmCode(
								phoneNumber,
								fullCode,
								formData.password,
								formData.name,
								formData.email
							);
						} else {
							console.error(
								"PIN invÃ¡lido. Certifique-se de que contÃ©m 6 dÃ­gitos."
							);
						}
					},
				}}
			/>
			<Modal
				type="setPassword"
				params={{
					openModal: true,
					onConfirm: (password) => handleActivateUser(password),
				}}
			/>
		</div>
	);
}

export default CreateAccount;