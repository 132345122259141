import { useTranslation } from "react-i18next";
import { useEffect } from "react";

import { TextSelectorEn } from "./TextSelector";

function TermsAndPrivacyEn({ type }) {
  let title = "Terms of Use";
  if (type === "privacy") {
    title = "Privacy Policies";
  }
  useEffect(() => {
    document.title = title;
  });
  return (
    <div className="c-terms-and-privacy">
      <h1 className="terms-and-privacy__title">{title}</h1>
      <TextSelectorEn type={type} />
    </div>
  );
}

export default TermsAndPrivacyEn;
