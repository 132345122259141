import Avatar from "@mui/material/Avatar";
import dayjs from "dayjs";
import i18n from "../../i18n/i18n";
import { AlertEditButton } from "../../components/alert_button/AlertEditButton";
import { AlertDeleteButton } from "../../components/alert_button/AlertDeleteButton";
import {
  AlertIconSelector,
  AlertRiskSelector,
  AlertTypeSelector,
} from "../../components/alertIconSelector/AlertIconSelector";
import { formatDateHour, formatDateWithoutHour } from "../../utils/formatDate";

export const alertTableConfig = {
  columns: [
    {
      id: "aler_icon",
      cell: (row) => (
        <Avatar className="icon--table-avatar">
          <AlertIconSelector
            level={row.alert_level_id}
            typeAlert={row.aler_tx_name}
          />
        </Avatar>
      ),
      sortable: false,
      searchable: false,
      width: "6rem",
      linkedOmit: "teste",
    },
    {
      id: "datetime",
      name: i18n.t("alerts.tableColumns.datetime"),
      cell: (row) => (
        <p className="datetime">{formatDateWithoutHour(dayjs(row.datetime))}</p>
      ),
      sortable: true,
      searchable: true,
      linkedOmit: null,
      width: "11.1rem",
    },
    {
      id: "start_time",
      name: i18n.t("alerts.tableColumns.start_time"),
      cell: (row) => (
        <p className="date_start">{dayjs(row.start_time).format("HH:mm")}</p>
      ),
      sortable: false,
      searchable: true,
      linkedOmit: null,
      width: "10rem",
    },
    {
      id: "end_time",
      name: i18n.t("alerts.tableColumns.end_time"),
      cell: (row) => (
        <p className="date_start">
          {dayjs(row.end_time).format("HH:mm")}
        </p>
      ),
      sortable: false,
      searchable: true,
      linkedOmit: null,
      width: "10rem",
    },
    /* TODO: Removendo temporariamente para sincronizar features com produÃ§Ã£o https://wiiglo.atlassian.net/browse/GPDS-344 */
    // {
    //   id: "date_end",
    //   name: i18n.t("alerts.tableColumns.date_end"),
    //   cell: (row) => (
    //     <p className="date_end">
    //       {dayjs(row.date_end).format("HH:mm")}
    //     </p>
    //   ),
    //   sortable: false,
    //   searchable: false,
    //   linkedOmit: null,
    //   width: "100px",
    // },
    {
      id: "title",
      name: i18n.t("alerts.tableColumns.title"),
      selector: (row) => (
        <p className="title" title={row.title} style={{ width: "20rem" }}>
          {row.title}
        </p>
      ),
      sortable: true,
      searchable: true,
      linkedOmit: null,
      width: "18rem",
    },
    {
      id: "aler_tx_name",
      name: i18n.t("alerts.tableColumns.aler_tx_name"),
      selector: (row) => (
        <p className="aler_tx_name" style={{ width: "20rem" }}>
          {i18n.t(AlertTypeSelector(row.aler_tx_name))}
        </p>
      ),
      sortable: true,
      searchable: true,
      linkedOmit: null,
      width: "13rem",
    },
    {
      id: "nive_tx_name",
      name: i18n.t("alerts.tableColumns.nive_tx_name"),
      selector: (row) => (
        <p className="nive_tx_name" style={{ width: "auto" }}>
          {" "}
          {i18n.t(AlertRiskSelector(row.nive_tx_name))}
        </p>
      ),
      sortable: false,
      searchable: false,
      linkedOmit: null,
      width: "auto",
      minWidth: "12rem !important",
    },
    /* TODO: Removendo temporariamente para sincronizar features com produÃ§Ã£o https://wiiglo.atlassian.net/browse/GPDS-344 */
    // {
    //   id: "Details",
    //   name: i18n.t("alerts.tableColumns.Details"),
    //   selector: (row) => <AlertDetails propsDate={row} />,
    //   sortable: false,
    //   searchable: false,
    //   linkedOmit: null,
    // },
    {
      id: "Actions",
      name: i18n.t("alerts.tableColumns.Actions"),
      cell: (row) => (
        <>
          <AlertEditButton id={row.id} />
          <AlertDeleteButton id={row.id} />
        </>
      ),
      sortable: false,
      searchable: false,
      linkedOmit: null,
      width: "20rem",
    },
  ],
  hiddenColumns: [],
  initialSort: "datetime",
  initialOrder: "desc",
  permission: "weather",
  hasSelectableRows: false,
  requestUrls: {
    get: "api/weather/alert",
  },
};
