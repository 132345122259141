import logoSidebarOpen from '../../assets/img/logo-cittua.png';
import symbolSidebarClosed from '../../assets/img/logoSymbol-typeA.png';
import i18n from '../i18n/i18n';

export const sidebarConfig = {
	logo: {
		open: logoSidebarOpen,
		closed: symbolSidebarClosed,
	},
	mainMenu: {
		items: [
			{
				id: 'weatherForecast',
				type: 'expandableItem',
				permission: 'home',
				itemIcon: 'LocationPin2',
				itemText: i18n.t('sidebar.weatherForecast'),
				subItemsList: [
					{
						id: 'nwpForecast',
						type: 'subItem',
						parent: 'weatherForecast',
						itemText: i18n.t('sidebar.nwpForecast'),
						itemPath: '/nwpForecast',
					},
					{
						id: 'forecasts',
						type: 'subItem',
						parent: 'weatherForecast',
						itemText: i18n.t('sidebar.forecasts'),
						itemPath: '/forecasts',
					},{
						id: 'alerts',
						type: 'subItem',
						parent: 'weatherForecast',
						itemText: i18n.t('sidebar.alerts'),
						itemPath: '/alerts',
					}
				],
			},
			{
                id: 'insights',
                type: 'expandableItem',
                permission: 'rainfall_report',
                itemIcon: 'Report',
                itemText: i18n.t('sidebar.insights'),
                subItemsList: [
                    {
                        id: 'rainReport',
                        type: 'subItem',
                        parent: 'insights',
                        itemText: i18n.t('sidebar.rainReport'),
                        itemPath: '/rain-occurrences',
                    },
                ]
            }
		]
	},
	subMenu: {
		items: [
			{
				id: 'users',
				type: 'generalItem',
				permission: 'user',
				itemIcon: 'Users2',
				itemText: i18n.t('sidebar.users'),
				itemPath: '/users',
			},
			{
				id: 'settings',
				type: 'generalItem',
				permission: 'user',
				itemIcon: 'Settings',
				itemText: i18n.t('sidebar.settings'),
				itemPath: '/settings',
			},
		]
	},
	get indexOrder() {
		const mainMenuItems = this.mainMenu.items.filter(element => element.type !== 'expandableItem');
		const subMenuItems = this.subMenu.items.filter(element => element.type !== 'expandableItem');
		const mainExpandableItems = this.mainMenu.items.filter(element => element.type === 'expandableItem');
		const subExpandableItems = this.subMenu.items.filter(element => element.type === 'expandableItem');
		let subItems = [];
		mainExpandableItems.forEach(element => subItems = [...subItems, ...element.subItemsList]);
		subExpandableItems.forEach(element => subItems = [...subItems, ...element.subItemsList]);

		return [...mainMenuItems, ...subMenuItems, ...subItems]
	}
}