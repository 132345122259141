import { SET_THEME } from '../actions/uiActions'
import { Cookies } from 'react-cookie'

const getInitialUiTheme = () => {
	const cookies = new Cookies()

	const theme = cookies.get('uiThemeCittua')

	return theme
}

const initialTheme = getInitialUiTheme()

const initialState = {
	theme: initialTheme !== undefined  ? initialTheme : 'light',
}

export default function uiConfig(state=initialState, action) {
	switch(action.type) {
	case SET_THEME:
		return {
			...state,
			theme: action.theme
		}
	default:
		return state
	}
}