import React from "react";
import ReactDOM from "react-dom/client";
import { createStore, compose } from "redux";
import { Provider } from "react-redux";
import { CookiesProvider } from "react-cookie";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { BrowserRouter } from "react-router-dom";

import "dayjs/locale/pt-br";
import "dayjs/locale/en";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";

import "./js/i18n/i18n";
import reducer from "./js/redux/reducers";
import middleware from "./js/redux/middleware";
import App from "./App";
import { browserLanguage, selectMuiLocale } from "./js/i18n/i18n";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <CookiesProvider>
    <Provider store={createStore(reducer, composeEnhancers(middleware))}>
      <BrowserRouter>
        <LocalizationProvider
          dateAdapter={AdapterDayjs}
          adapterLocale={browserLanguage()}
          localeText={
            selectMuiLocale().components.MuiLocalizationProvider.defaultProps
              .localeText
          }
        >
          <App />
        </LocalizationProvider>
      </BrowserRouter>
    </Provider>
  </CookiesProvider>
);
