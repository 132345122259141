import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useNavigate } from "react-router-dom"
import Button from '@mui/material/Button'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Avatar from '@mui/material/Avatar'
import Skeleton from '@mui/material/Skeleton'

import { requestLogout } from '../../utils/authAPI'
import IconSelector from '../IconSelector'

function UserProfileMenu({params}) {
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const navigate = useNavigate()
	const user = useSelector(state => state.user.data)
	const theme = useSelector(state => state.uiActions.theme)
	const [userMenuRef, setUserMenuRef] = useState(null)
	const [loading, setLoading] = useState(true)
	const [profilePhoto, setProfilePhoto] = useState(null)
	const [counter, setCounter] = useState(0)

	const onOpenMenu = (event) => {
		setUserMenuRef(event.currentTarget)
	}

	const onCloseMenu = () => {
		setUserMenuRef(null)
	}

	const onClickMenuItem = (event,index) => {
		setUserMenuRef(null)
		if (index === 0) {
			navigate('user-profile')
		}
		if (index === 2) {
			requestLogout(dispatch, navigate)
		}
	}

	useEffect(() => {
		if (Object.keys(user).length === 0) {
			setLoading(true)
		} else {
			setProfilePhoto(user.photo+'?'+ counter)
			setCounter(counter => counter + 1)
			setLoading(false)
		} 
	}, [user])

	return (
		<div className='c-right-component'>
			<Button onClick={onOpenMenu} className='btn-user-profile'>
				<div className={Boolean(userMenuRef) ? 'avatar-border avatar-border--open-menu' : 'avatar-border'}>
					{
						loading ?
						<Skeleton animation='wave' variant='circle' classes={{root: 'skeleton-avatar-img'}}/> :
						<Avatar
							src={profilePhoto}
							classes={{
								root: 'c-avatar-img'
							}}
						>
							<IconSelector svg='Users'/>
						</Avatar>
					}
				</div>
				<IconSelector svg='ChevronDown' classname={Boolean(userMenuRef) ? 'icon--open-menu' : 'icon--closed-menu'} />
			</Button>
			<Menu
				anchorEl={userMenuRef}
				open={Boolean(userMenuRef)}
				onClose={onCloseMenu}
				disableScrollLock={true}
				anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
    		transformOrigin={{ vertical: "top", horizontal: "right" }}
    		classes={{
    			paper: 'menu',
    			root: `theme--${theme}`
    		}}
			>
				<MenuItem classes={{root: 'menu-item'}} onClick={(event) => onClickMenuItem(event,0)}>{t('userProfileMenu.profile')}</MenuItem>
				{/*TODO: Funcionalidade de modo escuro. Omitida por enquanto para a entrega do projeto.
					<MenuItem disableRipple classes={{root: 'menu-item'}}>
					<label>{t('userProfileMenu.darkMode')}</label>
					<Switch 
						classes={{root: 'switch switch--margin-left'}}
						checked={darkMode}
						onChange={toggleTheme}
					/>
				</MenuItem>*/}
				<MenuItem classes={{root: 'menu-item'}} onClick={(event) => onClickMenuItem(event,2)}>{t('userProfileMenu.logout')}</MenuItem>
			</Menu>
		</div>
	)
}

export default UserProfileMenu