import IconSelector from "../../components/IconSelector";

export const RainIconSelector = ({ rainType }) => {
  const backendIcons = {
    "Não Chuva": "RainReportNoRain",
    "Chuva": "RainReportRain",
    "Sem Dados": "RainReportNoData",
  };

  const selectedIcon = backendIcons[rainType];

  if (!selectedIcon) {
    return null;
  }

  return <IconSelector svg={selectedIcon} />;
};
