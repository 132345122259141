import React from "react";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

const SettingsCard = ({ icon, title, subtitle, to }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(to);
  };
  return (
    <Button onClick={handleClick} className="settings-card" variant="outlined">
      <div className="settings-card-content">
        <div className="settings-card-icon">{icon}</div>
        <div className="settings-card-title">{title}</div>
        <div className="settings-card-subtitle">{subtitle}</div>
      </div>
    </Button>
  );
};

export default SettingsCard;
