import { Button } from "@mui/material";
import styled from "styled-components";
import { DateRangePicker } from "react-date-range";

export const CustomDiv = styled.div``;

export const StyledButton = styled(Button)`
  width: 15.1rem;
  height: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.8rem;
  background-color: #ffffff;
  border: 1px solid #4120ce;
  border-radius: 4px;
  color: #4120ce;
  font-size: 1.4rem;
  letter-spacing: 0;
  line-height: 100%;
  font-family: "Inter", sans-serif;
  font-weight: bold;
  text-transform: none;
`;

export const StyledDateRangePicker = styled(DateRangePicker)`
  font-family: "Inter", sans-serif;
  font-size: 1.4rem;
  letter-spacing: 0;
  line-height: 100%;
  .rdrWeekDay {
    font-family: "Inter", sans-serif;
  }
  .rdrMonthName {
    font-family: "Inter", sans-serif;
  }
  .rdrDayNumber {
    font-family: "Inter", sans-serif;
  }

  .rdrDefinedRangesWrapper {
    width: 0px;
    color: none;
    background-color: none;
    padding: 0;
    margin: 0;
    border: none;
  }
  .rdrDateDisplayWrapper {
    width: 0px;
    height: 0px;
    color: none;
    background-color: none;
    padding: 0;
    margin: 0;
    border: none;
  }
  .rdrDateDisplay {
    width: 0px;
    height: 0px;
    color: none;
    background-color: none;
    padding: 0;
    margin: 0;
    border: none;
  }
  .rdrDateInput {
    border: none;
  }

  .rdrInRange {
    color: #4120ce;
    background-color: #4120ce;
  }

  .rdrDayStartPreview  {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
  .rdrDayEndPreview {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  .rdrDayEndOfWeek .rdrStartEdge {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
  
  .rdrDayEndOfWeek .rdrInRange {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
  .rdrDayStartOfWeek .rdrInRange {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
  .rdrStartEdge {
    color: #4120ce;
    background-color: #4120ce;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
  .rdrEndEdge {
    color: #4120ce;
    background-color: #4120ce;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
  .rdrDayStartPreview {
    border-color: #4120ce;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }
  .rdrDayEndPreview {
    border-color: #4120ce;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
  .rdrDayInPreview {
    border-color: #4120ce;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }
`;
