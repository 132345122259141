import { getIn } from 'formik';
import { useTranslation } from 'react-i18next'

import IconSelector from '../IconSelector'

function TextAreaInput({params}) {
	const { t } = useTranslation()
	const { 
		id, 
		formType, 
		formProps, 
		isEditing, 
		editDisabled, 
		validations,
		formValue,
		handleFormChange,
		formInput,
		parentInput,
	} = params

	const setErrorMsgStyle = () => {
		if (
		formProps.errors[id] && 
		(formInput ?
			(getIn(formProps.touched, id) || getIn(formProps.touched, `${parentInput}.${id}`))
			:
			formProps.touched[id])
		) {
			return 'c-error-text'
		}

		return 'c-error-text c-error-text--hidden'
	}

	return (
		<div className="form-content-component">
			<label className='colored-label'>
				{t(`${formType}.fields.${id}`)}{(validations.filter(rule => rule.type === 'required').length > 0) && '*'}
			</label>
			<textarea
				id={id}
				name={id}
				className={(formProps.errors[id] && params.formProps.touched[id]) ? 'input input--area input--error' : 'input input--area c-input-text'}
				value={formInput ? formValue[formInput] : formProps.values[id]}
				onChange={
					formInput ? 
					(event) => handleFormChange({
						inputValue: event.target.value,
						input: id 
					}) : 
					formProps.handleChange}
				onBlur={formProps.handleBlur}
				disabled={formProps.isSubmitting || (isEditing && editDisabled)}
			>				
			</textarea>
			<div className={setErrorMsgStyle()}>
				<IconSelector svg={'ErrorWarningLine'} classname={'icon--warning'}/>
				<span className='error-text'>{formProps.errors[id]}</span>
			</div>
		</div>
	)
}

export default TextAreaInput