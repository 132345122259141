import { useEffect } from "react";

function MobileStoreRedirect() {

  const getMobileOperatingSystem = () => {

    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return "ios";
    }

    return "android";
  };

  useEffect(() => {

    const params = new URLSearchParams(window.location.search);
    const platform = params.get("platform") ?? getMobileOperatingSystem();

    const redirectPlatform = {
      android: "https://play.google.com/store/apps/details?id=com.cittua",
      ios: "https://apps.apple.com/br/app/cittua/id6444165017",
    };


    setTimeout(() => {  window.location = redirectPlatform[platform] }, 3000)

    window.location = 'cittua://' + window.location.pathname + window.location.search;
  }, []);

  return <></>;
}

export default MobileStoreRedirect;
