import { useTranslation } from 'react-i18next'
import Slider from '@mui/material/Slider';

function RangeSliderInput({params}) {
	const { t } = useTranslation()
	const { 
		id, 
		formType, 
		formProps, 
		validations, 
		scale,
		marks,
		formValue,
		handleFormChange,
		formInput,
	} = params

	const handleSliderChange = (event, newValue) => {
		if (formInput) {
			handleFormChange({
				inputValue: {
					[`min_${id}`]: newValue[0],
			    [`max_${id}`]: newValue[1]
				},
				input: id,
			})

			return
		}

		formProps.setFieldValue(id, {
			[`min_${id}`]: newValue[0],
			[`max_${id}`]: newValue[1]
		})
	}

	const handleInputChange = (event) => {
		if (event.target.id === `input_${id}_1`) {
			
			if(formInput) {
				handleFormChange({
					inputValue: {
						[`min_${id}`]: event.target.value === '' ? 0 : Number(event.target.value),
						[`max_${id}`]: formValue[formInput][`max_${id}`]
					},
					input: id,
				})

				return
			}

			formProps.setFieldValue(id, {
				[`min_${id}`]: event.target.value === '' ? 0 : Number(event.target.value),
				[`max_${id}`]: formProps.values[id][`max_${id}`]
			})
			
			return
		}

		if (formInput) {
			handleFormChange({
				inputValue: {
					[`min_${id}`]: formValue[formInput][`min_${id}`],
					[`max_${id}`]: event.target.value === '' ? 0 : Number(event.target.value)
				},
				input: id,
			})

			return
		}

		formProps.setFieldValue(id, {
			[`min_${id}`]: formProps.values[id][`min_${id}`],
			[`max_${id}`]: event.target.value === '' ? 0 : Number(event.target.value),
		})

	}


	return (
		<>
			<label className='label'>
				{t(`${formType}.fields.${id}`)}{(validations.filter(rule => rule.type === 'required').length > 0) && '*'}
			</label>
			<div className='c-range-slider'>
				<input
					id={`input_${id}_1`}
					value={
					formInput ?
					formValue[formInput][`min_${id}`]
					: formProps.values[id][`min_${id}`]}
					onChange={handleInputChange}
					type='number'
					min={scale.min}
					max={scale.max}
					className='range-slider-input'
				/>
				<Slider
					value={		
					formInput ?
					[formValue[formInput][`min_${id}`], formValue[formInput][`max_${id}`]]
					: [formProps.values[id][`min_${id}`],formProps.values[id][`max_${id}`]]}
					onChange={handleSliderChange}
					min={scale.min}
					max={scale.max}
					valueLabelDisplay="on"
					className='range-slider'
					marks={marks}
				/>
				<input
					id={`input_${id}_2`}
					value={
					formInput ?
					formValue[formInput][`max_${id}`]
					: formProps.values[id][`max_${id}`]}
					onChange={handleInputChange}
					type='number'
					min={scale.min}
					max={scale.max}
					className='range-slider-input'
				/>
			</div>
		</>
	)

}

export default RangeSliderInput