import { useTranslation } from "react-i18next";
import { Switch } from "../Switch/Switch";
import * as S from "./RainOccurrence.styled";

export const RainOccurrence = ({ handleSelectedOccurrences, disable }) => {
  const { t } = useTranslation();

  return (
    <S.StyledContainer>
      <S.OccurrenceText>
        {t("rainReport.tableColumns.occurrence")}:
      </S.OccurrenceText>
      <S.RainText>{t("rainReport.rainIntensity.rain")}</S.RainText>
      <Switch
        id="Chuva"
        handleSelectedOccurrences={handleSelectedOccurrences}
        disable={disable}
      />
      <S.RainText>{t("rainReport.rainIntensity.noRain")}</S.RainText>
      <Switch
        id="Não Chuva"
        handleSelectedOccurrences={handleSelectedOccurrences}
        disable={disable}
      />
      <S.RainText>{t("rainReport.rainIntensity.noData")}</S.RainText>
      <Switch
        id="Sem Dados"
        handleSelectedOccurrences={handleSelectedOccurrences}
        disable={disable}
      />
    </S.StyledContainer>
  );
};
