import { useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { StyledEngineProvider } from "@mui/material/styles";
import { Navigate } from "react-router-dom";

import { pageview } from "./js/utils/googleAnalytics";
import Login from "./js/components/Login";
import PrivateRoutes from "./js/components/PrivateRoutes";
import SetPasswordPage from "./js/components/SetPasswordPage";
import TermsAndPrivacyPt from "./js/components/TermsAndPrivacyPt";
import Home from "./js/components/Home";
import NwpForecast from "./js/components/NwpForecast";
import ForecastDetails from "./js/components/ForecastDetails";
import UsersForm from "./js/components/forms/UsersForm";
import ForecastsForm from "./js/components/forms/ForecastsForm";
import AlertsForm from "./js/components/forms/AlertsForm";
import TenantSelection from "./js/components/TenantSelection";
import ErrorPage from "./js/components/ErrorPage";
import UserProfile from "./js/components/UserProfile";
import "./css/styles.scss";
import "./js/components/Users/css/index.css"
import AlertTable from "./js/components/tables/AlertTable";
import ForecastTable from "./js/components/tables/ForecastTable";
import UserTable from "./js/components/tables/UserTable";
import MobileStoreRedirect from "./js/components/MobileStoreRedirect";
import RainReportTable from "./js/components/tables/RainReportTable";
import TermsAndPrivacyEn from "./js/components/TermsAndPrivacyEn";
import { Settings } from "./pages/Settings/Settings";
import { PersonalInformation } from "./pages/PersonalInformation/PersonalInformation";
import { OrgInformation } from "./pages/OrgInformation/OrgInformation";
import { TeamMembers } from "./pages/TeamMembers/TeamMembers";
import { Locations } from "./pages/Locations/Locations";
import { Tools } from "./pages/Tools/Tools";
import CreateAccount from "./js/components/createAccount/CreateAccountForm";
import InfoLocals from "./js/components/Users/pages/InfoLocals";
import CreateLocal from "./js/components/Users/pages/CreateLocal";
import UpdateLocal from "./js/components/Users/pages/EditLocal";
import UsersLayout from "./js/components/Users/components/Layout";
import InfoAccount from "./js/components/Users/pages/InfoAccount";
import EditAccount from "./js/components/Users/pages/EditAccount";

function App() {
  let location = useLocation();

  useEffect(() => {
    pageview(location.pathname, document.title);
  }, [location]);

  return (
    <StyledEngineProvider injectFirst>
      <Routes>
        <Route path="/" element={<PrivateRoutes />}>
          <Route index element={<Home />} />
          <Route path="nwpForecast" element={<NwpForecast />} />
          <Route path="forecasts" element={<ForecastTable key={"forecasts"} />} />
          <Route path="alerts" element={<AlertTable key={"alerts"} />} />
          <Route path="user-profile" element={<UserProfile />} />
          <Route path="users" element={<UserTable key={"users"} />} />
          <Route path="forecasts/details/:id" element={<ForecastDetails />} />
          <Route path="users/create" element={<UsersForm />} />
          <Route path="users/edit/:itemId" element={<UsersForm />} />
          <Route path="forecasts/create" element={<ForecastsForm />} />
          <Route path="forecasts/edit/:itemId" element={<ForecastsForm />} />
          <Route path="alerts/create" element={<AlertsForm />} />
          <Route path="alerts/edit/:itemId" element={<AlertsForm />} />
          <Route path="rain-occurrences" element={<RainReportTable key={"rainReport"} />} />
          <Route path="settings" element={<Settings />} />
          <Route path="personal-information" element={<PersonalInformation />} />
          <Route path="org-information" element={<OrgInformation />} />
          <Route path="team-members" element={<TeamMembers />} />
          <Route path="locations" element={<Locations />} />
          <Route path="tools" element={<Tools />} />
        </Route>
        <Route path="/login" element={<Login />} />

        <Route path="/user-area" element={<UsersLayout />}>
          <Route path="create-account" element={<CreateAccount />} />
          <Route path="info-locations" element={<InfoLocals />} />
          <Route path="create-locations" element={<CreateLocal />} />
          <Route path="edit-locations/:localNome" element={<UpdateLocal />} />
          <Route path="info-account" element={<InfoAccount />} />
          <Route path="edit-account" element={<EditAccount />} />
        </Route>
        <Route path="/select-tenant" element={<TenantSelection />} />
        <Route path="/recovery" element={<SetPasswordPage type="recovery" />} />
        <Route
          path="/activate/:token"
          element={<SetPasswordPage type="activate" />}
        />
        <Route path="/terms/en" element={<TermsAndPrivacyEn type="terms" />} />
        <Route
          path="/privacy/en"
          element={<TermsAndPrivacyEn type="privacy" />}
        />
        <Route path="/terms" element={<TermsAndPrivacyPt type="terms" />} />
        <Route path="/privacy" element={<TermsAndPrivacyPt type="privacy" />} />
        <Route path="/store/*" element={<MobileStoreRedirect />} />
        <Route
          path="/404"
          element={<ErrorPage params={{ type: "pageNotFound" }} />}
        />
        <Route path="*" element={<Navigate to="/404" replace />} />
      </Routes>
    </StyledEngineProvider>
  );
}

export default App;
