import TextInput from '../../form_elements/TextInput'

function PersonalData({props}) {
	return (
		<>
			<div className='input-controls'>
				<TextInput
					params={{										
						id: 'first_name',
						editDisabled: false,
						validations: [
							{
								type: 'required',
								value: true,
							},
							{
								type: 'minLength',
								value: 3,
							},
						],
						formType: 'users',
						formProps: props.formProps,
						isEditing: props.itemId,
					}}
				/>
			</div>
			<div className='input-controls'>
				<TextInput
					params={{										
						id: 'last_name',
						editDisabled: false,
						validations: [
							{
								type: 'required',
								value: true,
							},
							{
								type: 'minLength',
								value: 3,
							},
						],
						formType: 'users',
						formProps: props.formProps,
						isEditing: props.itemId,
					}}
				/>	
			</div>
			<div className='input-controls'>
				<TextInput
					params={{										
						id: 'cpf',
						editDisabled: false,
						mask: {
							format: '###.###.###-##',
							placeholder: '_',
							unformatValue: true,
						},
						validations: [
							{
								type: 'required',
							},
							{
								type: 'cpf',
							}
						],
						formType: 'users',
						formProps: props.formProps,
						isEditing: props.itemId,
					}}
				/>
			</div>
			<div className='input-controls'>
				<TextInput
					params={{										
						id: 'social_name',
						editDisabled: false,
						validations: [
							{
								type: 'minLength',
								value: 3,
							},
						],
						formType: 'users',
						formProps: props.formProps,
						isEditing: props.itemId,
					}}
				/>
			</div>
			<div className='input-controls'>
				<TextInput
					params={{										
						id: 'birth_date',
						editDisabled: false,
						mask: {
							format: '##/##/####',
							placeholder: ['D','D','M','M','A','A','A','A'],
							unformatValue: false,
						},
						validations: [
							{
								type: 'date',
							}
						],
						formType: 'users',
						formProps: {...props.formProps},
						isEditing: props.itemId,
					}}
				/>
			</div>
		</>
	)
}

export default PersonalData