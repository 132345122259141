import { useTranslation } from "react-i18next";
import { FormControlLabel, RadioGroup, Radio } from "@mui/material";

import IconSelector from "../IconSelector";
import { iconConfig } from "../../data/icon-config";

function RadioGroupInput({ params }) {
  const { t } = useTranslation();
  const {
    id,
    formType,
    formProps,
    isEditing,
    editDisabled,
    validations,
    options,
    setIconField,
    iconOptions,
    iconLabel,
    handleFormChange,
  } = params;

  const handleRadioChange = (event) => {
    if (handleFormChange) {
      handleFormChange({
        inputValue: event.target.value,
        input: id,
      });
    }

    formProps.setFieldValue(id, event.target.value);

    if (setIconField) {
      formProps.setFieldValue(iconLabel, iconOptions[event.target.value]);
    }
  };

  const setErrorMsgStyle = () => {
    if (formProps.errors[id] && formProps.touched[id]) {
      return "c-error-text";
    }
    return "c-error-text c-error-text--hidden";
  };

  return (
    <div className="form-content-component">
      <label className="colored-label">
        {t(`${formType}.fields.${id}`)}
        {validations.filter((rule) => rule.type === "required").length > 0 &&
          "*"}
      </label>
      <RadioGroup
        row
        id={id}
        name={id}
        value={formProps.values[id]}
        onChange={handleRadioChange}
        onBlur={formProps.handleBlur}
        disabled={formProps.isSubmitting || (isEditing && editDisabled)}
      >
        {options &&
          options.map((option, index) => (
            <div
              className={
                String(formProps.values[id]) === String(option.value)
                  ? option.checkedStyle
                  : option.uncheckedStyle
              }
              key={index}
            >
              <FormControlLabel
                value={option.value}
                control={
                  <Radio
                    icon={
                      <IconSelector
                        svg={iconConfig[option.icon]}
                        classname="icon--radio-button"
                      />
                    }
                    checkedIcon={
                      <IconSelector
                        svg={iconConfig[option.icon]}
                        classname="icon--radio-button"
                      />
                    }
                  />
                }
                label={t(option.label)}
                className="radio-button-label"
              />
            </div>
          ))}
      </RadioGroup>
      {formProps.errors[id] ? (
        <div className={setErrorMsgStyle()}>
          <IconSelector svg={"ErrorWarningLine"} classname={"icon--warning"} />
          <span className="error-text">{formProps.errors[id]}</span>
        </div>
      ) : null}
    </div>
  );
}

export default RadioGroupInput;
