import { useSelector } from 'react-redux'

import ErrorPage from './ErrorPage';

function PermissionsControl(props) {
	const userPermissions = useSelector(state => state.user.data.permissions)

	if ((userPermissions && userPermissions[props.role] && userPermissions[props.role][props.action]) ||  (userPermissions && !userPermissions[props.role])) {
		return props.children
	} else if (userPermissions && props.type === 'componentItem') {
		return null
	} else if (userPermissions && props.type === 'componentPage') {
		return <ErrorPage params={{type: 'permissionsError', permissionType: props.role}}/>
	} else {
		return null
	}
}

export default PermissionsControl