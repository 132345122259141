import { useState } from "react";
import { useSelector } from "react-redux";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import IconSelector from "../IconSelector";
import PermissionsControl from "../PermissionsControl";
import { useTranslation } from "react-i18next";

function MultiActionMenu({ params }) {
  const { t } = useTranslation();

  const theme = useSelector((state) => state.uiActions.theme);
  const [menuRef, setMenuRef] = useState(null);
  const { configTable } = params;

  const onOpenMenu = (event) => {
    setMenuRef(event.currentTarget);
  };

  const onCloseMenu = (event) => {
    setMenuRef(null);
  };

  const onClickMenuItem = (event, option) => {
    params.setAction(option);
    onCloseMenu(event);
    params.openConfirmModal();
  };

  return (
    <>
      {
        <div>
          <PermissionsControl
            role={configTable.permission}
            type="componentItem"
            action="update"
          >
            <Button
              onClick={onOpenMenu}
              classes={{
                root: "btn-secondary btn-secondary--medium hider-btn hide-columns-menu",
              }}
              endIcon={<IconSelector svg="ChevronDown" />}
            >
              {t("headerTableBtn.massActions")}
            </Button>
            <Menu
              anchorEl={menuRef}
              open={Boolean(menuRef)}
              onClose={onCloseMenu}
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              transformOrigin={{ vertical: "top", horizontal: "right" }}
              classes={{ paper: "menu", root: `theme--${theme}` }}
              disableScrollLock={true}
            >
              <MenuItem
                onClick={(event) => onClickMenuItem(event, "activate")}
                classes={{ root: "menu-item" }}
              >
                <IconSelector svg="CheckCircle" classname="icon--menu-item" />
                {t("headerTableBtn.activeAction")}
              </MenuItem>
              <MenuItem
                onClick={(event) => onClickMenuItem(event, "deactivate")}
                classes={{ root: "menu-item" }}
              >
                <IconSelector svg="CloseCircle" classname="icon--menu-item" />
                {t("headerTableBtn.disableAction")}
              </MenuItem>
            </Menu>
          </PermissionsControl>
        </div>
      }
    </>
  );
}

export default MultiActionMenu;
