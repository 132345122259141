import Button from "@mui/material/Button";

import { styled } from "@mui/material/styles";
import { Menu, MenuItem } from "@mui/material";

export const StyledButton = styled(Button)(({ theme }) => ({
  height: "4rem",
  width: "13.6rem",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: "1.2rem",
  backgroundColor: "#4120ce",
  borderRadius: "0.4rem",
  color: "#ffffff",
  fontSize: "1.4rem",
  lineHeight: "100%",
  fontWeight: "bold",
  textTransform: "none",
  "&:hover": {
    backgroundColor: "#4120ce",
    borderRadius: "0.4rem",
    color: "#ffffff",
  },
}));

export const StyledMenu = styled(Menu)(({ theme }) => ({}));

export const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  height: "32px",
  "&:hover": {
    backgroundColor: "#EDEAFA",
  },
}));
