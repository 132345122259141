import React from 'react'
import UserLocalForm from '../components/UserLocalForm'
import '../css/UpdateLocal.css'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useCookies } from 'react-cookie'

function UpdateLocal() {
  const { t } = useTranslation()
  const { localNome } = useParams()
  const [cookies] = useCookies()
  const localStorageUserLocations = cookies.userLocals ?? []
  const localToUpdate = localStorageUserLocations.find(local => local.nome === localNome)

  return (
    <div className='form-container'>
      <h1>{t("locations.editLocation.title")}</h1>
      <p>{t("locations.editLocation.text")}</p>
      <UserLocalForm buttonAddText={t("locations.editLocation.btn")} FormData={localToUpdate} />
    </div>
  )
}

export default UpdateLocal