import React, { Component } from 'react';
import { connect } from 'react-redux';

import ErrorPage from './ErrorPage';
import { setErrorStatus, setErrorType } from '../redux/actions/errors';

class ErrorBoundary extends Component {
	constructor(props){
		super(props);
		this.state = {
			hasError: false,
			errorMsg: null,
			errorInfo: null,
			errorCount: 0
		};
	}

	resetError =  () => {
		this.setState({
			hasError: false,
			errorCount: this.state.errorCount + 1,
		})

		this.props.dispatch(setErrorStatus(false))
		this.props.dispatch(setErrorType(null))
	}

	static getDerivedStateFromError(error) {
		return {hasError: true};
	}

	componentDidCatch(error, info) {    		//local para enviar os erros para serviço externo
	}

	render() {
		if (this.state.hasError) {
			return <ErrorPage params={{reset: this.resetError, type: 'jsError'}}/>
		} else if (this.props.errors.hasError) {
			return <ErrorPage params={{reset: this.resetError, type: this.props.errors.errorType}}/>
		} else {
				return (
					<React.Fragment key={this.state.errorCount}>
						{this.props.children}
					</React.Fragment>
				)
		}

	}
}

function mapStateToProps({errors}) {
	return {
		errors
	}
}

export default connect(mapStateToProps)(ErrorBoundary)