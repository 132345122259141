import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
//TODO: Validação de interseção com os boundaries removida temporariamente (tarefa CT-247)
//import { useSelector } from 'react-redux'

import CustomForm from "../../CustomForm";
import PermissionsControl from "../../PermissionsControl";
import MapPolygonInput from "../../form_elements/MapPolygonInput";
import TextInput from "../../form_elements/TextInput";
import TextAreaInput from "../../form_elements/TextAreaInput";
import RadioGroupInput from "../../form_elements/RadioGroupInput";
import { AlertTypeSelector } from "../../alertIconSelector/AlertIconSelector";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import IconSelector from "../../IconSelector";

dayjs.extend(utc);

// No banco, os valores 1, 2 e 6 já estão sendo usados, não utilizar aqui
const alertOptions = [
  {
    value: "3",
    label: AlertTypeSelector("storm"),
    icon: "storm",
    uncheckedStyle: "c-radio-button",
    checkedStyle: "c-radio-button c-radio-button--checked",
  },
  {
    value: "4",
    label: AlertTypeSelector("landslide"),
    icon: "landslide",
    uncheckedStyle: "c-radio-button",
    checkedStyle: "c-radio-button c-radio-button--checked",
  },
  {
    value: "5",
    label: AlertTypeSelector("flooding"),
    icon: "flooding",
    uncheckedStyle: "c-radio-button",
    checkedStyle: "c-radio-button c-radio-button--checked",
  },
  {
    value: "7",
    label: AlertTypeSelector("dry"),
    icon: "dry",
    uncheckedStyle: "c-radio-button",
    checkedStyle: "c-radio-button c-radio-button--checked",
  },
  {
    value: "8",
    label: AlertTypeSelector("sun"),
    icon: "sun",
    uncheckedStyle: "c-radio-button",
    checkedStyle: "c-radio-button c-radio-button--checked",
  },
  {
    value: "9",
    label: AlertTypeSelector("windstorm"),
    icon: "windstorm",
    uncheckedStyle: "c-radio-button",
    checkedStyle: "c-radio-button c-radio-button--checked",
  }
];

const levelOptions = [
  {
    value: 4,
    label: "alerts.riskLevel.veryHight",
    icon: "very_high_risk",
    uncheckedStyle: "c-radio-button c-radio-button--very-high-risk",
    checkedStyle: "c-radio-button c-radio-button--checked-vh-risk",
  },
  {
    value: 3,
    label: "alerts.riskLevel.hight",
    icon: "high_risk",
    uncheckedStyle: "c-radio-button c-radio-button--high-risk",
    checkedStyle: "c-radio-button c-radio-button--checked-high-risk",
  },
  {
    value: 2,
    label: "alerts.riskLevel.medium",
    icon: "medium_risk",
    uncheckedStyle: "c-radio-button c-radio-button--medium-risk",
    checkedStyle: "c-radio-button c-radio-button--checked-medium-risk",
  },
  {
    value: 1,
    label: "alerts.riskLevel.low",
    icon: "low_risk",
    uncheckedStyle: "c-radio-button c-radio-button--low-risk",
    checkedStyle: "c-radio-button c-radio-button--checked-low-risk",
  },
];

function AlertsForm() {
  const { t } = useTranslation();
  const { itemId } = useParams();
  const [date, setDate] = useState();
  const [time, setTime] = useState();
  const [startTime, setStartTime] = useState();
  const [endTime, setEndTime] = useState();
  const [duration, setDuration] = useState();

  const categories = ["AlertData"];
  //TODO: Validação de interseção com os boundaries removida temporariamente (tarefa CT-247)
  //const isInsideBoundary = useSelector(state => state.formValidation.isInsideBoundary)

  function getInitialValues(item) {
    let initialValues = {
      location: item?.location || "",
      title: item?.title || "",
      id_alert: String(item?.id_alert || ""),
      id_level: String(item?.id_level || ""),
      description: item?.description || "",
      duration: item?.duration || "",
      recommendations: item?.recommendations || "", 
      start_time: item?.start_time ? dayjs.utc(item.start_time) : "", 
      end_time: item?.end_time ? dayjs.utc(item.end_time) : "",
      publication_time: item?.publication_time
        ? dayjs.utc()
            .startOf("day")
            .add(item.publication_time, "minute")
            .format("HH:mm")
        : "",
    };

    return initialValues;
  }

  function formValidation(values) {
    let errors = {};

    //TODO: Validação de interseção com os boundaries removida temporariamente (tarefa CT-247)
    /*else if (values.location && !isInsideBoundary) {
        errors.location = t('common.inputErrors.outOfBounds')
    }*/

    if (!values.location) {
      errors.location = t("common.inputErrors.fieldRequired");
    }

    if (!values.title) {
      errors.title = t("common.inputErrors.fieldRequired");
    }

    if (!values.id_alert) {
      errors.id_alert = t("common.inputErrors.fieldRequired");
    }

    if (!values.id_level) {
      errors.id_level = t("common.inputErrors.fieldRequired");
    }

    if (!values.description) {
      errors.description = t("common.inputErrors.fieldRequired");
    }

    if (!values.recommendations) {
      errors.recommendations = t("common.inputErrors.fieldRequired");
    }

    if (!values.start_time) {
      errors.start_time = t("common.inputErrors.fieldRequired");
    }

    // Horário de início não pode ser anterior ao horário de publicação
    if (
      values.start_time &&
      values.publication_time &&
      values.start_time.format("HH:mm") < values.publication_time
    ) {errors.start_time = t("common.inputErrors.invalidStartTime")}

    if (!values.end_time) {
      errors.end_time = t("common.inputErrors.fieldRequired");
    }

    if (!values.publication_time) {
      errors.publication_time = t("common.inputErrors.fieldRequired");
    }

    // Horário de publicação não pode ser anterior ao horário atual
    if (
      values.publication_time &&
      dayjs().format("HH:mm") > values.publication_time
    ) {errors.publication_time = t("common.inputErrors.invalidPublicationTime")}

    return errors;
  }

  function formatSubmitValues(values) {
    // Calcula a duração baseado dos horários de início e fim
    const startTime = dayjs(values.start_time);
    const endTime = dayjs(values.end_time);

    // Se endTime for menor que startTime, adiciona um dia (caso de intervalo cruzando a meia-noite)
    const durationInMinutes =
    endTime.isBefore(startTime)
      ? endTime.add(1, "day").diff(startTime, "minute")
      : endTime.diff(startTime, "minute");

    const durationInHours = Math.max(Math.ceil(durationInMinutes / 60), 0);

    let submitValues = {
      form: {
        ...values,
        publication_time: values.publication_time, 
        start_time: startTime.format("YYYY-MM-DDTHH:mm:ss"), 
        end_time: endTime.format("YYYY-MM-DDTHH:mm:ss"), 
        duration: durationInHours,
      },
      requestUrl: itemId ? `api/weather/alert/${itemId}` : "api/weather/alert",
      method: itemId ? "patch" : "post",
    };

    return submitValues;
  }

  useEffect(() => {
    document.title = itemId
      ? t("alerts.pageTitle.edit")
      : t("alerts.pageTitle.create");
  });

  useEffect(() => {
    if (startTime && endTime) {
      const durationInMinutes = endTime.diff(startTime, "minute");
      const durationInHours = Math.max(Math.ceil(durationInMinutes / 60), 0);
      setDuration(durationInHours);
    }
  }, [startTime, endTime]);

  return (
    <div className="c-main-content c-main-content--form">
      <PermissionsControl role="weather" type="componentPage" action="update">
        <CustomForm
          params={{
            formType: "alerts",
            categories,
            getInitialValues,
            formValidation,
            formatSubmitValues,
            requestData: {
              requestUrl: "api/weather/alert",
              method: "get",
            },
          }}
        >
          {(formProps) => (
            <>
              <div className="c-input">
                <MapPolygonInput
                  params={{
                    id: "location",
                    editDisabled: false,
                    validations: [
                      {
                        type: "boundaryValidation",
                      },
                      {
                        type: "required",
                      },
                    ],
                    previewForecasts: false,
                    mapTimeSettings: {
                      date,
                      setDate,
                      time,
                      setTime,
                    },
                    formType: "alerts",
                    formProps,
                    isEditing: itemId,
                  }}
                />
              </div>
              <div className="c-input time-group">
                <label className="colored-label form-time-input-label" htmlFor="start_time">
                  {`${t("alerts.tableColumns.start_time")}*`}
                </label>
                <TimePicker
                  ampm={false}
                  views={["hours", "minutes"]}
                  format="HH:mm"
                  className={`form-time-input ${
                    formProps.errors.start_time && formProps.touched.start_time ? "error-border" : ""
                  }`}
                  value={formProps.values.start_time || null}
                  onChange={(newValue) => {
                    formProps.setFieldValue("start_time", newValue);
                  }}
                  disabled={formProps.isSubmitting}
                  required
                />
                <div
									className={
										formProps.errors.start_time && formProps.touched.start_time
											? "c-error-text time-error"
											: "c-error-text c-error-text--hidden"
									}
								>
									<IconSelector
										svg="ErrorWarningLine"
										classname="icon--warning"
									/>
									<span className="error-text">{formProps.errors.start_time}</span>
								</div>
              </div>
              <div className="c-input time-group">
                <label className="colored-label" htmlFor="end_time">
                  {`${t("alerts.tableColumns.end_time")}*`}
                </label>
                <TimePicker
                  ampm={false}
                  views={["hours", "minutes"]}
                  format="HH:mm"
                  className={`form-time-input ${
                    formProps.errors.end_time && formProps.touched.end_time ? "error-border" : ""
                  }`}
                  value={formProps.values.end_time || null}
                  onChange={(newValue) => {
                    formProps.setFieldValue("end_time", newValue);
                  }}
                  disabled={formProps.isSubmitting}
                  required
                />
                <div
									className={
										formProps.errors.end_time && formProps.touched.end_time
											? "c-error-text time-error"
											: "c-error-text c-error-text--hidden"
									}
								>
									<IconSelector
										svg="ErrorWarningLine"
										classname="icon--warning"
									/>
									<span className="error-text">{formProps.errors.end_time}</span>
								</div>
              </div>
              <div className="c-input" style={{ display: "none" }}>
                <label htmlFor="duration">
                  Duração (horas)
                </label>
                <input
                  type="text"
                  id="duration"
                  value={duration}
                />
              </div>
              <div className="c-input alert-title">
                <TextInput
                  params={{
                    id: "title",
                    editDisabled: false,
                    validations: [
                      {
                        type: "required",
                      },
                      {
                        type: "maxLength",
                        value: 100,
                      },
                    ],
                    formType: "alerts",
                    formProps,
                    showTextLengthCounter: true,
                    isEditing: itemId,
                  }}
                />
              </div>
              <div className="c-input">
                <RadioGroupInput
                  params={{
                    id: "id_alert",
                    editDisabled: false,
                    setIconField: false,
                    options: alertOptions,
                    validations: [
                      {
                        type: "required",
                      },
                    ],
                    formType: "alerts",
                    formProps,
                    isEditing: itemId,
                  }}
                />
              </div>
              <div className="c-input">
                <RadioGroupInput
                  params={{
                    id: "id_level",
                    editDisabled: false,
                    setIconField: false,
                    options: levelOptions,
                    validations: [
                      {
                        type: "required",
                      },
                    ],
                    formType: "alerts",
                    formProps,
                    isEditing: itemId,
                  }}
                />
              </div>
              <div className="c-input alert-text-area">
                <TextAreaInput
                  params={{
                    id: "description",
                    editDisabled: false,
                    validations: [
                      {
                        type: "required",
                      },
                    ],
                    formType: "alerts",
                    formProps,
                    isEditing: itemId,
                  }}
                />
              </div>
              <div className="c-input alert-text-area">
                <TextAreaInput
                  params={{
                    id: "recommendations",
                    editDisabled: false,
                    validations: [
                      {
                        type: "required",
                      },
                    ],
                    formType: "alerts",
                    formProps,
                    isEditing: itemId,
                  }}
                />
              </div>
            </>
          )}
        </CustomForm>
      </PermissionsControl>
    </div>
  );
}

export default AlertsForm;