import { combineReducers } from "redux";

import user from "./user";
import errors from "./errors";
import uiActions from "./uiActions";
import tableReducer from "./tableReducer";
import formValidation from "./formValidation";
import formDate from "./formDate";


export default combineReducers({
  user,
  errors,
  uiActions,
  tableReducer,
  formValidation,
  formDate
});
