import { useState } from "react";
import dayjs from "dayjs";

import MapPolygonInput from "../../form_elements/MapPolygonInput";
import TextInput from "../../form_elements/TextInput";

function LocalData({ props }) {
  const [date, setDate] = useState(dayjs());
  const [time, setTime] = useState(dayjs());

  return (
    <>
      <div className="c-input">
        <MapPolygonInput
          params={{
            id: "location",
            editDisabled: false,
            formProps: props.formProps,
            formType: "forecasts",
            previewForecasts: true,
            mapTimeSettings: {
              date,
              setDate,
              time,
              setTime,
            },
            validations: [
              {
                type: "boundaryValidation",
              },
              {
                type: "required",
              },
              {
                type: "intersectionValidation",
              },
            ],
          }}
        />
      </div>
      <div className="c-input-container">
        <TextInput
          params={{
            id: "alias",
            editDisabled: false,
            formProps: props.formProps,
            formType: "forecasts",
            showTextLengthCounter: true,
            validations: [
              {
                type: "required",
              },
              {
                type: "maxLength",
                value: 100,
              },
            ],
          }}
        />
      </div>
    </>
  );
}

export default LocalData;
