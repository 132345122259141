import { useNavigate } from "react-router-dom";
import UserLocalCard from "../components/UserLocalCard";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import Modal from "../components/Modal";
import cittuaLogo from "../assets/Logo.svg";
import QRCode from 'react-qr-code';
import { getUserAlertsLocations } from "../../../utils/dataAPI";
import { useCookies } from 'react-cookie';


function InfoLocals() {
  const navigate = useNavigate();
  const [cookies] = useCookies();
  const localStorageUserLocations = cookies.userLocals ?? []
  const url = window.location.href;
  const host = url.split("/")[3];
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(0);
  const downloadLink = "https://play.google.com/store/apps/details?id=com.cittua&pli=1";
  const dispatch = useDispatch();

  const textI18nHasLocalLength = {
    pageTitle: t("locations.showLocations.titleHasLocal"),
    qrCodeBox: {
      title: t("locations.showLocations.qrCodeBox.titleHasLocal"),
      text: t("locations.showLocations.qrCodeBox.textHasLocal"),
    }
  }
  const textI18nNoLocalLength = {
    pageTitle: t("locations.showLocations.titleNoLocal"),
    qrCodeBox: {
      title: t("locations.showLocations.qrCodeBox.titleNoLocal"),
      text: t("locations.showLocations.qrCodeBox.textNoLocal"),
    }
  }

  const fetchUserLocals = async () => {
    const userLocalResponse = await getUserAlertsLocations(dispatch);
    console.log(userLocalResponse)
  };

  useEffect(() => {
    fetchUserLocals();
  }, [])

  const textI18n = localStorageUserLocations && localStorageUserLocations.length ? textI18nHasLocalLength : textI18nNoLocalLength;

  const handleCreateLocal = () => {
    if (localStorageUserLocations.length >= 3) {
      handleNextModal();
      return;
    }
    navigate(`/${host}/create-locations`);
  };

  const handleNextModal = () => setShowModal((prev) => prev + 1);
  const handleCloseModal = () => setShowModal(0);

  useEffect(() => {
    if (!localStorageUserLocations.length) {
      const userAlertsLocations = getUserAlertsLocations();

    }
  }, [])

  return (
    <div className='info-locations-container'>
      <div className="intro">
        <h2>{textI18n.pageTitle}</h2>
        <p>
          {t('locations.showLocations.text1')}
          <strong>{t('locations.showLocations.text2')}</strong>
          {t('locations.showLocations.text3')}
          <strong>{t('locations.showLocations.text4')}</strong>
          {t('locations.showLocations.text5')}
          <strong>{t('locations.showLocations.text6')}</strong>
          {t('locations.showLocations.text7')}
        </p>
      </div>
      <div className="locals-list">
        {localStorageUserLocations.map((local, index) => (
          <div key={index} className="local-card">
            <UserLocalCard local={local} />
          </div>
        ))}
      </div>
      <button className="add-location-btn" onClick={handleCreateLocal}>+ Novo local</button>
      <div className="card">
        <h3>{textI18n.qrCodeBox.title}</h3>
        <p>{textI18n.qrCodeBox.text}</p>
        <div className="image-placeholder">
          <QRCode value={downloadLink} className="" />
        </div>
      </div>
      {showModal === 1 && (
        <Modal
          title="Assine o plano Starter para cadastrar mais locais"
          text="Plano inicial que permite um maior controle dos seus locais"
          icon={cittuaLogo}
          btnActionText={"Assinar"}
          handleCloseModal={handleCloseModal}
          handleButtonAction={handleNextModal}
          iconCSSClass={"plan-modal-icon"}
          titleCSSClass={"plan-modal-title"}
          textCSSClass={"plan-modal-description"}
        />
      )}
      {showModal === 2 && (
        <Modal
          title="O plano Starter será disponibilizado em breve"
          text="Entraremos em contato através do e-mail cadastrado e do aplicativo Cittua"
          icon={cittuaLogo}
          handleCloseModal={handleCloseModal}
          iconCSSClass={"plan-modal-icon"}
          titleCSSClass={"plan-modal-title"}
          textCSSClass={"plan-modal-description"}
        />
      )}
    </div>
  );
}

export default InfoLocals 