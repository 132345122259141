export const iconConfig = {
	flooding: 'Flooding',
	landslide: 'Landslide',
	cold: 'Cold',
	cyclone: 'Cyclone',
	earthquake: 'Earthquake',
	dry: 'Dry',
	sun: 'Sunny',
	storm: 'Storm',
	sol: 'Sunny',
	nublado: 'Cloudy',
	chuva: 'Rain',
	tempestade: 'Storm',
	lua: 'CleanSky',
	weak: 'Wind',
	moderate: 'Wind',
	strong: 'Wind',
	very_strong: 'Wind',
	windstorm: 'Windstorm',
	weather: 'Storm',
	very_high_risk: 'WarningVeryHigh',
	high_risk: 'WarningHigh',
	medium_risk: 'WarningMedium',
	low_risk: 'WarningLow',


}