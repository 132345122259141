import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import dayjs from "dayjs";

import { getTableItem } from "../utils/dataAPI";
import IconSelector from "./IconSelector";
import Skeleton from "@mui/material/Skeleton";
import ErrorPage from "./ErrorPage";
import {
  skyConditionSelector,
  windConditionSelector,
} from "./forms/ForecastsForm/constants";

const forecastPeriodSelection = (forecastPeriod) => {
  const forecastPeriods = {
    Tarde: "forecasts.fields.multiForm.afternoon",
    Noite: "forecasts.fields.multiForm.night",
    Madrugada: "forecasts.fields.multiForm.dawn",
    "Amanhã de manhã": "forecasts.fields.multiForm.tomorrow_morning",
  };
  return forecastPeriods[forecastPeriod];
};

function ForecastDetails() {
  const { id } = useParams();
  const { t } = useTranslation();
  const [itemDetails, setItemDetails] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const goToEdit = () => {
    navigate(`../forecasts/edit/${id}`);
  };

  const goBack = () => {
    navigate("../forecasts");
  };

  useEffect(() => {
    const fetchItemData = async () => {
      setLoading(true);
      let requestParams = {
        url: "api/weather/forecast",
        id: id,
        method: "get",
      };
      try {
        const response = await getTableItem(requestParams, dispatch);

        if (response && response.success) {
          setItemDetails(response.data[0]);
        } else {
          setError(true);
        }
        setLoading(false);
      } catch (error) {
        setError(true);
        setLoading(false);
      }
    };

    fetchItemData();
  }, []);

  return (
    <div className="c-main-content c-main-content--form">
      {error ? (
        <ErrorPage
          params={{ type: "detailsError", detailsType: "forecasts" }}
        />
      ) : loading ? (
        <>
          {[...Array(6)].map((element, index) => (
            <div className="c-details-item" key={index}>
              <Skeleton
                animation="wave"
                variant="rectangular"
                classes={{ root: "skeleton-details-label" }}
              />
              <Skeleton
                animation="wave"
                variant="rectangular"
                classes={{ root: "skeleton-details-value" }}
              />
            </div>
          ))}
        </>
      ) : (
        <div className="c-details-content">
          {Object.keys(itemDetails).length !== 0 && (
            <>
              <div className="c-details-item">
                <p className="details-label">
                  {t("forecasts.details.datetime")}
                </p>
                <p>{dayjs(itemDetails.datetime).format("DD/MM/YYYY")}</p>
              </div>
              <div className="c-details-item">
                <p className="details-label">{t("forecasts.details.alias")}</p>
                <p>{itemDetails.alias}</p>
              </div>
              {itemDetails.forecast_24h.map((itemForecast, index) => (
                <Accordion
                  key={itemForecast[`titulo_${index + 1}`]}
                  className="c-multi-form"
                >
                  <AccordionSummary
                    expandIcon={
                      <IconSelector
                        svg="ChevronDown"
                        classname={"icon--multi-form-chevron"}
                      />
                    }
                    classes={{
                      root: "multi-form-summary",
                      content: "multi-form-content",
                    }}
                  >
                    <span className="multi-form-title">
                      {t(
                        forecastPeriodSelection(
                          itemForecast[`titulo_${index + 1}`]
                        )
                      )}
                    </span>
                  </AccordionSummary>
                  <AccordionDetails classes={{ root: "group-details" }}>
                    <div className="c-details-item">
                      <p className="details-label">
                        {t("forecasts.details.sky_condition")}
                      </p>
                      <p>
                        {t(
                          skyConditionSelector(
                            itemForecast[`sky_condition_${index + 1}`]
                          )
                        )}
                      </p>
                    </div>
                    <div className="c-details-item">
                      <p className="details-label">
                        {t("forecasts.details.min_temperature")}
                      </p>
                      <p>
                        {
                          itemForecast[`temperature_${index + 1}`][
                            `min_temperature_${index + 1}`
                          ]
                        }
                      </p>
                    </div>
                    <div className="c-details-item">
                      <p className="details-label">
                        {t("forecasts.details.max_temperature")}
                      </p>
                      <p>
                        {
                          itemForecast[`temperature_${index + 1}`][
                            `max_temperature_${index + 1}`
                          ]
                        }
                      </p>
                    </div>
                    <div className="c-details-item">
                      <p className="details-label">
                        {t("forecasts.details.wind_condition")}
                      </p>
                      <p>
                        {t(
                          windConditionSelector(
                            itemForecast[`wind_condition_${index + 1}`]
                          )
                        )}
                      </p>
                    </div>
                    <div className="c-details-item">
                      <p className="details-label">
                        {t("forecasts.details.min_humidity")}
                      </p>
                      <p>
                        {
                          itemForecast[`humidity_${index + 1}`][
                            `min_humidity_${index + 1}`
                          ]
                        }
                      </p>
                    </div>
                    <div className="c-details-item">
                      <p className="details-label">
                        {t("forecasts.details.max_humidity")}
                      </p>
                      <p>
                        {
                          itemForecast[`humidity_${index + 1}`][
                            `max_humidity_${index + 1}`
                          ]
                        }
                      </p>
                    </div>
                    <div className="c-details-item">
                      <p className="details-label">
                        {t("forecasts.details.description")}
                      </p>
                      <p>{itemForecast[`description_${index + 1}`]}</p>
                    </div>
                  </AccordionDetails>
                </Accordion>
              ))}
            </>
          )}
          <div className="c-nav-btn">
            <Button
              className="btn-primary btn-primary--large-full-width"
              onClick={goToEdit}
            >
              {t("forecasts.editItemBtn")}
            </Button>
            <Button
              className="btn-secondary btn-secondary--large-full-width btn-secondary--margin-top-3"
              onClick={goBack}
            >
              {t("common.form.btn.backBtn")}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}

export default ForecastDetails;
