import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useCookies, Cookies } from 'react-cookie';
import { createUserAlertLocation, updateUserAlertsLocations } from '../../../utils/dataAPI';


function UserLocalForm({ FormData, buttonAddText }) {
  const [formData, setFormData] = useState(FormData ?? { nome: '', local: '' })
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const host = location.pathname.split('/')[1]
  const cookies = new Cookies()
  const [getCookies] = useCookies()

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.id]: e.target.value
    })
  }

  const validateNameLength = formData.nome.length > 2
  const validateLocalLength = formData.local.length > 2

  const validateForm = validateNameLength && validateLocalLength

  const validateNameExistsAlready = (arrayOfLocals, formName) => arrayOfLocals.some(local => local.nome === formName)

  const handleSubmit = (e) => {
    e.preventDefault()

    if (validateForm) {
      const oldUserLocals = getCookies.userLocals ?? []

      if (FormData) {
        const updatingUserLocal = oldUserLocals.find(local => local.nome === FormData.nome)
        const index = oldUserLocals.indexOf(updatingUserLocal)
        oldUserLocals[index] = formData
        const newUserLocals = [...oldUserLocals]
        cookies.set('userLocals', newUserLocals)
        console.log('Local atualizado com sucesso')
        updateUserAlertsLocations({ address: formData.local, address_name: formData.nome })
      } else {
        if (validateNameExistsAlready(oldUserLocals, formData.nome)) {
          console.log('Nome já cadastrado')
          return
        }

        const data = {
          nome: formData.nome,
          local: formData.local
        }
        const newUserLocals = [...oldUserLocals, data]
        cookies.set('userLocals', newUserLocals)
        console.log('Local cadastrado com sucesso')
        createUserAlertLocation({ address: formData.local, address_name: formData.nome })
      }
      navigate(`/${host}/info-locations`)
    } else {
      console.log('Preencha todos os campos corretamente')
    }

  }


  return (
    <form className="local-form" onSubmit={handleSubmit}>
      <div className="form-group">
        <label htmlFor="nome">{t("locations.formsCommon.fields.name")}</label>
        <input
          type="text"
          id="nome"
          name="nome"
          placeholder={t("locations.formsCommon.fieldsPlaceholder.name")}
          value={formData.nome}
          onChange={handleChange}
        />
      </div>
      <div className="form-group">
        <label htmlFor="local">{t("locations.formsCommon.fields.location")}</label>
        <input
          type="text"
          id="local"
          name="local"
          placeholder={t("locations.formsCommon.fieldsPlaceholder.location")}
          value={formData.local}
          onChange={handleChange}
        />
      </div>
      <div className="button-group">
        <button type="submit" className="primary-btn">
          {buttonAddText}
        </button>
        <Link type="button" to={`/${host}/info-locations`} className="secondary-btn">
          Voltar
        </Link>
      </div>
    </form>
  )
}

export default UserLocalForm