import HideColumnsMenu from "../../table_header_elements/HideColumnsMenu";
import NewTableItemBtn from "../../table_header_elements/NewTableItemBtn";
import SearchTableBtn from "../../table_header_elements/SearchTableBtn";
import ExportTableMenu from "../../table_header_elements/ExportTableMenu";
import MultiActionMenu from "../../table_header_elements/MultiActionMenu";

export function HeaderUserTable({ params }) {
  
  return (
    <div className="content-header-user">
      <div className="content-form-MultiAction">
        <MultiActionMenu params={params} />,
      </div>
      <div className="content-form">
        <div className="content-form-Export">
          <ExportTableMenu params={params} />,
        </div>
        <div className="content-form">
          <HideColumnsMenu params={params} />
        </div>
        <div className="content-form">
          <SearchTableBtn params={params} />
        </div>
        <div className="content-form">
          <NewTableItemBtn params={params} />,
        </div>
      </div>
    </div>
  );
}
