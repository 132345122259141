import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemIcon from '@mui/material/ListItemIcon';
import Avatar from '@mui/material/Avatar';

import IconSelector from '../IconSelector';
import { sidebarConfig } from '../../data/sidebar-config';

function SidebarItem({props}) {
	let itemIndex = sidebarConfig.indexOrder.findIndex(element => element.id === props.itemConfig.id)

	return (
		<ListItem
			classes={{root: props.sidebarStatus ? 'sidebar-item' : 'sidebar-item sidebar-item--closed'}}
			onClick={() => props.onClickFunction(itemIndex)}
			selected={props.selectedIndex === itemIndex}
			button
		>
			<ListItemAvatar classes={{root: 'sidebar-item__icon-wrapper'}}>
				<Avatar classes={{root: 'sidebar-item__icon'}}>
					<IconSelector 
						svg={props.itemConfig.itemIcon} 
						classname={'icon--sidebar'}
					/>
				</Avatar>
			</ListItemAvatar>
			<ListItemText 
				classes={{primary: props.sidebarStatus ? 'sidebar-item__text' : 'sidebar-item__text--hidden'}} 
				primary={props.itemConfig.itemText}
			/>
			<ListItemIcon classes={{root: 'sidebar-item__secondary-icon'}}>
				<IconSelector 
					svg='ChevronRight' 
					classname={'icon--sidebar'}
				/>
			</ListItemIcon>
		</ListItem>
	)
}

export default SidebarItem