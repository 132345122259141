import { useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';


function Home() {
	const { t } = useTranslation();
	const navigate = useNavigate()

	useEffect(() => {
		document.title = t('home.pageTitle')
		navigate('/nwpForecast')
	})


	return (
		<div className='c-main-content c-main-content--home'>
			<div className='home-content'>

			</div>
		</div>
	)
}

export default Home
