import { useTranslation } from 'react-i18next'
import CircularProgress from '@mui/material/CircularProgress'

function BtnLoadingText() {
	const { t } = useTranslation()
	return (
		<div className='c-btn-loading'>
			<CircularProgress className='icon--loading' thickness={4} size={24}/>
			<span className='btn-loading'>{t('common.form.btn.loading')}</span>
		</div>
	)
}

export default BtnLoadingText