export const TRIGGER_FETCH_DATA = 'TRIGGER_FETCH_DATA'


export function triggerFetchData(bool) {
  return {
    type: TRIGGER_FETCH_DATA,
    shouldFetchData: bool
  }
} 


