import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Formik } from 'formik'
import { useParams, useNavigate } from "react-router-dom"
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepButton from '@mui/material/StepButton'
import Button from '@mui/material/Button'
import Skeleton from '@mui/material/Skeleton'
import dayjs from "dayjs"
import customParseFormat from 'dayjs/plugin/customParseFormat'

import { createEditTableItem, getTableItem } from '../utils/dataAPI'
import { event } from '../utils/googleAnalytics'
import IconSelector from './IconSelector'
import Modal from './Modal'
import ErrorPage from './ErrorPage'
import BtnLoadingText from './BtnLoadingText'

function CustomForm({params, children}) {
	dayjs.extend(customParseFormat)
	const { itemId } = useParams()
	const { t } = useTranslation()
	const { 
		formType, 
		setActiveTab, 
		categories, 
		getInitialValues, 
		formValidation,
		formatSubmitValues,
		requestData,
 } = params
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const [activeStep, setActiveStep] = useState(0)
	const [openResultModal, setOpenResultModal] = useState(false)
	const [actionSuccess, setActionSuccess] = useState(false)
	const [actionMessage, setActionMessage] = useState(null)
	const [actionType, setActionType] = useState(null)
	const [item, setItem] = useState({})
	const [loading, setLoading] = useState(true)
	const [formError, setFormError] = useState(false)

	const handleStep = (step) => {
		setActiveStep(step)
		setActiveTab(step)
	}

	const handlePreviousStep = () => {
		setActiveStep(currentStep => {
			return currentStep-1
		})
		setActiveTab(currentStep => {
			return currentStep-1
		})
	}

	function setSubmitBtnTxt(formProps) {
		if (formProps.isSubmitting) {
			return <BtnLoadingText/>
		}

		if (categories.length === 1) {
			return t('common.form.btn.saveBtn')
		}

		if (activeStep === categories.length - 1) {
			return t(`${formType}.formBtn`)
		}

		return t('common.form.btn.forwardBtn')
	}

	const formSubmit = (values,actions) => {
		//google analytics info//
		event(`${actionType}_${formType}_submit`, `${actionType}_item_submit`, `${formType}_${actionType}_item_submit`)

		const submitAction = () => {
			let updatedItem = {...values}
			for (let input in values) {
				if (values[input] === '' || values[input] === 'none') {
					updatedItem[input] = null
				}
			}

			let arg = formatSubmitValues(updatedItem)

			createEditTableItem(arg, dispatch).then(response => {
				if (response?.success) {
					//google analytics info//
					event(`${actionType}_${formType}_success`, `${actionType}_item_success`, `${formType}_${actionType}_item_success`)

					actions.setSubmitting(false)
					setActionSuccess(true)
					setOpenResultModal(true)
				} else {
					//google analytics info//
					event(`${actionType}_${formType}_failed`, `${actionType}_item_failed`, `${formType}_${actionType}_item_failed`)

					actions.setSubmitting(false)
					setActionSuccess(false)
					response ? setActionMessage(t(`${formType}.${itemId ? 'edit' : 'create'}.failed.text`)) : setActionMessage(null)
					setOpenResultModal(true)
				}
			})			
		}

		if(!itemId) {			
			if(categories.length - 1 === activeStep) {
				submitAction()
				return
			}

			actions.setTouched({})
			actions.setSubmitting(false)
			setActiveStep(currentStep => {
				return currentStep + 1
			})
			setActiveTab(currentStep => {
				return currentStep + 1
			})

			return
		} 
		
		submitAction()	
	}

	const closeResultModal = () => {
		setOpenResultModal(false)

		if (actionSuccess) {
			navigate(`/${formType}`)
		}
	}

	//Sets the action performed in the form (create or edit)//
	useEffect(() => {
		setActionType(itemId ? 'edit' : 'create')
	}, [itemId])

	//Gets the form fields' values if the action is to update an item//
	useEffect(() => {
		const fetchData = async () => {
			try {
				if (itemId) {
					const params = {
						url: requestData.requestUrl,
						id: itemId,
						method: requestData.method,
					}

					const response = await getTableItem(params, dispatch)

					if (response?.success) {
						setItem(Array.isArray(response.data) ? response.data[0] : response.data)
					} else {
						setFormError(true)
					}
				}

				setLoading(false)
			} catch (error) {
				setFormError(true)
				setLoading(false)
			}
		}

		fetchData()
		
	}, [itemId, formType, requestData, dispatch])

	return (
		<>
			{
				formError ?
				<ErrorPage params={{type: 'formError', formType: formType}}/>
				:
				<>
					{
						categories.length > 1 &&
						<Stepper classes={{root: 'c-form-tabs'}}nonLinear activeStep={activeStep} connector={<></>}>
							{
								categories.map((category,index) => (
								<Step classes={{root: 'c-form-tab-btn'}} key={category}>
									<StepButton classes={{root: activeStep === index ? 'form-tab-btn form-tab-btn--active' : 'form-tab-btn'}} onClick={() => handleStep(index)} disabled={!itemId} icon={<span>{`${index+1}.`}</span>}>
										{t(`${formType}.formTabs.${category}`)}
									</StepButton>
								</Step>
								))
							}
						</Stepper>
					}
					<Formik
						initialValues = {getInitialValues(item)}
						validate={values => formValidation(values)}
						onSubmit={(values,actions) => formSubmit(values,actions) }
						enableReinitialize={true}
					>
					{
						formProps => 						
							<form 
								onSubmit={formProps.handleSubmit} 
								className={formType === 'userProfile' ? 'user-profile-form' : 'general-form'}
							>
								{ loading ?  
									<>
										{[...Array(6)].map((element, index) => 
											<div className='c-input' key={index}>
												<Skeleton animation='wave' variant='rectangular' classes={{root: 'skeleton-form-label'}}/>
												<Skeleton animation='wave' variant='rectangular' classes={{root: 'skeleton-form-input'}}/>
											</div>
										)}
									</> :
									children(formProps) 
								}													
								<div className='c-form-btn'>
									{
										(itemId) ?
											loading ?
											<Skeleton animation='wave' variant='rectangular' classes={{root: 'skeleton-form-btn'}}/>
										  :
										  <Button type='submit' disabled={formProps.isSubmitting} className='btn-primary btn-primary--large'>
										  	{
										  		formProps.isSubmitting ?
										  		<BtnLoadingText/>	:
										  		t('common.form.btn.saveBtn')
										  	}
										  </Button>
										:
										<>
											{(activeStep !==0) && <Button className='btn-secondary btn-secondary--large btn-secondary--margin-right' onClick={handlePreviousStep}><IconSelector svg='ChevronLeft' classname='icon--arrow-left-btn'/>{t('common.form.btn.backBtn')}</Button>}		
											<Button className='btn-primary btn-primary--large' type='submit' disabled={formProps.isSubmitting}>
											{setSubmitBtnTxt(formProps)}
											{(categories.length !== 1) && <IconSelector svg='ChevronRight' classname='icon--arrow-right-btn'/>}
											</Button>
										</>
									}
								</div>
							</form>						
					}
					</Formik>
					<Modal
						type='formCreateResult' 
						params={{
							openModal: openResultModal,
							closeModal: closeResultModal,
							formType: params.formType,
							actionSuccess: actionSuccess,
							actionType: actionType,
							actionMessage: actionMessage,
						}}
					/>
				</>
			}
		</>
	)
}

export default CustomForm