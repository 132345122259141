import React, { useState, useRef, useEffect } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import IconSelector from "../IconSelector";
import ptBr from "date-fns/locale/pt-BR";
import "../../../css/2-components/DatePickerStyles.scss";
import enUs from "date-fns/locale/en-US";
import { useTranslation } from "react-i18next";
import { selectLocale } from "../../i18n/i18n";

function DatePickerHeaderTable({
  onChange,
  Update,
  title = true,
  minDate = null,
  maxDate = null,
}) {
  const { t } = useTranslation();

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [showStartDateArrow, setShowStartDateArrow] = useState(true);
  const [showEndDateArrow, setShowEndDateArrow] = useState(true);
  const startDatePickerRef = useRef(null);
  const endDatePickerRef = useRef(null);
  const [dateStart, setDateStart] = useState(null);
  const [dateEnd, setDateEnd] = useState(null);

  const clearStartDate = () => {
    setStartDate(null);
    setShowStartDateArrow(true);
    setDateStart(null);
    Update();
  };

  const clearEndDate = () => {
    setEndDate(null);
    setShowEndDateArrow(true);
    setDateEnd(null);
    Update();
  };

  useEffect(() => {
    onChange({
      dateStart: dateStart,
      dateEnd: dateEnd,
    });
  }, [dateStart, dateEnd]);

  useEffect(() => {
    if (startDate) {
      setEndDate(null);
    }
  }, [startDate]);

  const handleStartDateChange = (name, date) => {
    setDateStart(date);
    setStartDate(date);
    setShowStartDateArrow(false);
  };

  const handleEndDateChange = (name, date) => {
    setDateEnd(date);
    setEndDate(date);
    setShowEndDateArrow(false);
  };

  const openStartDatePicker = () => {
    startDatePickerRef.current.setOpen(true);
  };

  const openEndDatePicker = () => {
    endDatePickerRef.current.setOpen(true);
  };

  registerLocale("pt-BR", ptBr);
  registerLocale("en-US", enUs);

  return (
    <div className="date-picker-container">
      {title ? (
        <span className="titleSeparator">{t("headerTableBtn.dateFilter")}</span>
      ) : null}
      <div>
        <div className="input-container">
          <DatePicker
            selected={startDate}
            onChange={(date) => handleStartDateChange("dateStart", date)}
            placeholderText={t("headerTableBtn.start")}
            name="dateStart"
            startDate={startDate}
            endDate={endDate}
            maxDate={maxDate}
            minDate={minDate}
            dateFormat="dd/MM/yyyy"
            className={"date-input"}
            fixedHeight
            locale={selectLocale()}
            ref={startDatePickerRef}
          />
          <div className="calendar-icon" onClick={openStartDatePicker}>
            {showStartDateArrow && (
              <IconSelector svg="DownArrow" classname={"arrow-date"} />
            )}
          </div>
          {startDate && (
            <button className="clear-button" onClick={clearStartDate}>
              <IconSelector svg="Fechar" classname={"close-button"} />
            </button>
          )}
        </div>
      </div>
      <span className="separator">a</span>
      <div>
        <div className="input-container">
          <DatePicker
            selected={endDate}
            onChange={(date) => handleEndDateChange("dateEnd", date)}
            placeholderText={t("headerTableBtn.end")}
            startDate={startDate}
            endDate={endDate}
            minDate={startDate}
            maxDate={maxDate}
            fixedHeight
            locale={selectLocale()}
            name="dateEnd"
            dateFormat="dd/MM/yyyy"
            className={"date-input"}
            ref={endDatePickerRef}
          />
          <div className="calendar-icon" onClick={openEndDatePicker}>
            {showEndDateArrow && (
              <IconSelector svg="DownArrow" classname={"arrow-date"} />
            )}
          </div>
          {endDate && (
            <button className="clear-button" onClick={clearEndDate}>
              <IconSelector svg="Fechar" classname={"close-button"} />
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default DatePickerHeaderTable;
