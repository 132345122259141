import { Link, Outlet, useLocation } from 'react-router-dom'
import Cittua_Logo from '../assets/Logo.svg'
import profileIcon from '../assets/Profile.svg'
import localIcon from '../assets/Location.svg'
import exitIcon from '../assets/Exit.svg'
import '../css/index.css'
import { useTranslation } from 'react-i18next'
import { Cookies } from 'react-cookie'

function UsersLayout() {
  const { t } = useTranslation();
  const location = useLocation();
  const host = location.pathname.split('/')[1];
  const pageLocation = location.pathname.split('/')[2];
  const cookies = new Cookies();

  const accountsPageActive = pageLocation.includes('account') && 'active' || "";
  const locationsPageActive = pageLocation.includes('locations') && 'active' || "";

  const pageTitle = pageLocation.includes('account') ? t("simpleHeader.title.profile") : t("simpleHeader.title.myPlaces");

  const handleLogout = () => {
    cookies.remove('user');
    cookies.remove('userLocals');
  }

  return (
    <div className="app-container">
      <aside className="sidebar">
        <div className='logo-container'>
          <img className='logo' src={Cittua_Logo} />
        </div>
        <nav>
          <ul>
            <li className={accountsPageActive}>
              <Link to={`/${host}/info-account`}>
                <img src={profileIcon} />
                Perfil
              </Link>
            </li>
            <li className={locationsPageActive}>
              <Link to={`/${host}/info-locations`}>
                <img src={localIcon} />
                Meus locais
              </Link>
            </li>
            <div className='sidebar-breakpoint'></div>
            <li>
              <Link to="/" onClick={() => handleLogout()}>
                <img src={exitIcon} />
                Sair
              </Link>
            </li>
          </ul>
        </nav>
      </aside>
      <main className="main-content">
        <header className='header'>
          <h1>{pageTitle}</h1>
        </header>
        <section className="content-container">
          <main className='content'>
            <Outlet />
          </main>
        </section>
      </main>
    </div>
  );
}

export default UsersLayout