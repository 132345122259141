import profileIcon from '../assets/Profile_Roxo.svg';
import mailIcon from '../assets/Mail.svg';
import phoneIcon from '../assets/Phone.svg';
import { Link, useLocation } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { getMobileUserData } from '../../../utils/dataAPI'

function InfoAccount() {
  const location = useLocation();
  const host = location.pathname.split("/")[1];

  const [userData, setUserData] = useState({
    first_name: undefined,
    phone: undefined,
    email: undefined,
  });

  const fetchUserData = async () => {
    try {
      const response = await getMobileUserData();
  
      if (response && response.success && response.data) {
        setUserData({
          name: `${response.data.first_name || ""} ${response.data.last_name || ""}`.trim() || "Nome não informado",
          phone: response.data.phone || response.data.mobile || "Telefone não informado",
          email: response.data["e-mail"] || "E-mail não informado",
        });
      } else {
        console.warn("API falhou, buscando dados do usuário no localStorage...");
        const savedUserInfo = localStorage.getItem("userInfo");
  
        if (savedUserInfo) {
          setUserData(JSON.parse(savedUserInfo));
        } else {
          console.error("Nenhum dado do usuário encontrado no localStorage.");
        }
      }
    } catch (error) {
      console.error("Erro ao buscar dados do usuário:", error);
      const savedUserInfo = localStorage.getItem("userInfo");
  
      if (savedUserInfo) {
        setUserData(JSON.parse(savedUserInfo));
      } else {
        console.error("Nenhum dado do usuário encontrado no localStorage.");
      }
    }
  };  

  const formatPhoneNumber = (phone) => {
    if (!phone) return "Telefone não informado";
    const cleanedPhone = phone.replace(/\D/g, "");

    const hasCountryCode = cleanedPhone.startsWith("55");
    const formattedPhone = hasCountryCode ? cleanedPhone.slice(2) : cleanedPhone;

    if (formattedPhone.length === 11) {
      const areaCode = formattedPhone.slice(0, 2);
      const prefix = formattedPhone.slice(2, 7);
      const lineNumber = formattedPhone.slice(7);

      return `(${areaCode}) ${prefix}-${lineNumber}`;
    }

    if (formattedPhone.length === 10) {
      const areaCode = formattedPhone.slice(0, 2);
      const prefix = formattedPhone.slice(2, 6);
      const lineNumber = formattedPhone.slice(6);

      return `(${areaCode}) ${prefix}-${lineNumber}`;
    }

    return "Formato de telefone inválido";
  };

  useEffect(() => {
    fetchUserData();
  }, []);

  return (
    <div className="form-container">
      <h1 className="title-account">Informações pessoais</h1>
      <p className="subtitle-account">Atualize suas informações pessoais</p>

      <div className="info-item">
        <div className="info-account-icon">
          <img src={profileIcon} alt="Profile icon" />
        </div>
        <span>
          <strong>Nome:</strong> {userData.name || "Nome não informado"}
        </span>
      </div>

      <div className="info-item">
        <div className="info-account-icon">
          <img src={phoneIcon} alt="Phone icon" />
        </div>
        <span>
          <strong>Celular:</strong> {formatPhoneNumber(userData.phone)}
        </span>
      </div>

      <div className="info-item">
        <div className="info-account-icon">
          <img src={mailIcon} alt="Mail icon" />
        </div>
        <span>
          <strong>E-mail de login:</strong> {userData.email || "E-mail não informado"}
        </span>
      </div>

      <Link to={`/${host}/edit-account`} className="edit-button">
        Editar Perfil
      </Link>
    </div>
  );
}

export default InfoAccount;
