import { Button, Dialog, DialogTitle, IconButton } from "@mui/material";
import IconSelector from "../IconSelector";
import { useState } from "react";
import { styled } from "@mui/material/styles";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { triggerFetchData } from "../../redux/actions/triggerFetchData";
import { deleteAlertItem } from "../../utils/dataAPI";
import ExcluirIcon from "../../../assets/icons/Excluir.svg";


export function AlertDeleteButton({ id }) {
  const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
      padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
      padding: theme.spacing(1),
    },
  }));

  const [open, setOpen] = useState(false);
  const [openModalTwo, setOpenModalTwo] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleClickOpen = () => {
    setOpen(true);
    setOpenModalTwo(false);
  };

  const handleClose = () => {
    setOpen(false);
    setOpenModalTwo(false);

    if (openModalTwo) {
      dispatch(triggerFetchData(true));
    }
  };

  const handleModal = () => {
    try {
      setOpen(false);
      deleteAlertItem(id);
      setOpenModalTwo(true);
    } catch (err) {
      return err;
    }
  };

  return (
    <>
      <Button
        className="btn-table"
        variant="outlined"
        onClick={handleClickOpen}
      >
        <img src={ExcluirIcon} alt="Excluir" className="icon" />
        <p className="delete-btn-text">
          {t("forecasts.button.deleteButton")}
        </p>
      </Button>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle
          className="Exclusion-Modal"
          sx={{ m: 0, p: 2 }}
          id="customized-dialog-title"
        >
          <IconSelector svg="Notification" className="warning" />
          <h1 className="exclusion-alert">{t("exclusion.alert")}</h1>
          <p className="exclusion-alert-warning">{t("exclusion.warning")}</p>
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <IconSelector svg="Fechar" className="close" />
        </IconButton>
        <div className="group-buttons">
          <Button className="btn-ModalOne" autoFocus onClick={handleModal}>
            {t("exclusion.persist")}
          </Button>
          <Button className="btn-ModalTwo" autoFocus onClick={handleClose}>
            {t("exclusion.to go back")}
          </Button>
        </div>
      </BootstrapDialog>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={openModalTwo}
      >
        <DialogTitle
          className="Modal-Two"
          sx={{ m: 0, p: 2 }}
          id="customized-dialog-title"
        >
          <IconSelector svg="CheckV" className="icon-two" />
          <h1 className="exclusion-alert">{t("alerts.delete.confirmation")}</h1>
          <p className="exclusion-alert-warning">
            {t("alerts.delete.success")}
          </p>
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <IconSelector svg="Fechar" className="close" />
        </IconButton>
        <Button className="btn-Modal" autoFocus onClick={handleClose}>
           <p className="deletion-confirmation" >
          {t("alerts.delete.exclusion")}
           </p>
        </Button>
      </BootstrapDialog>
    </>
  );
}
