import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Checkbox from "@mui/material/Checkbox";
import { event } from "../../utils/googleAnalytics";
import IconSelector from "../IconSelector";
import LinesEllipsis from "react-lines-ellipsis";

function HideColumnsMenu({ params }) {
  const { t } = useTranslation();
  const theme = useSelector((state) => state.uiActions.theme);
  const { tableType, hideColumn, configTable } = params;
  const [menuRef, setMenuRef] = useState(null);
  const onOpenMenu = (event) => {
    setMenuRef(event.currentTarget);
  };

  const onCloseMenu = (event) => {
    setMenuRef(null);
  };

  const onHideColumn = (index) => {
    //google analytics info//
    event(
      `filter_${tableType}_columns`,
      `filter_table_columns`,
      `${tableType}_table_columns`
    );

    let newState = [...hideColumn];
    newState[index] = !hideColumn[index];
    params.setHideColumn(newState);
  };

  return (
    <div className="hide-columns-container">
      <Button
        onClick={onOpenMenu}
        classes={{ root: "btn-secondary btn-secondary--medium hider-btn hide-columns-menu hide-columns-content" }}
      >
        {t('headerTableBtn.hideColumns')}
        <IconSelector svg="DownArrow" />
      </Button>
      <Menu
        anchorEl={menuRef}
        open={Boolean(menuRef)}
        onClose={onCloseMenu}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        classes={{ paper: "menu", root: `theme--${theme}` }}
        disableScrollLock={true}
      >
        {configTable.columns.map((column, index) => (
          <MenuItem
            key={index}
            classes={{
              root:
                column.linkedOmit === null ? "menu-item" : "menu-item--hidden",
            }}
            onClick={() => onHideColumn(index)}
          >
            <Checkbox
              name={`hideColumn[${index}]`}
              checked={params.hideColumn[index]}
              icon={
                <IconSelector
                  svg={"Checkbox"}
                  classname={"icon--table-checkbox table-check "}
                />
              }
              checkedIcon={
                <IconSelector
                  svg={"CheckedCheckbox"}
                  classname={"icon--table-checkbox-checked table-check"}
                />
              }
            />
            <LinesEllipsis
              text={t(`${params.tableType}.tableColumns.${column.id}`)}
              maxLine={"2"}
              ellipsis={"..."}
              trimRight={true}
              basedOn={"letters"}
              className={"lines-ellipsis"}
            />
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}

export default HideColumnsMenu;
