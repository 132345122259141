import SidebarItem from './sidebar_elements/SidebarItem';
import SidebarExpandItem from './sidebar_elements/SidebarExpandItem';

function SidebarElement({props}) {
	switch (props.itemConfig.type) {
		case 'generalItem':
			return (
				<SidebarItem props={props}/>
			)
		case 'expandableItem':
			return (
				<SidebarExpandItem props={props}/>
			)
		default:
			return null
	}
}

export default SidebarElement