import { Cookies } from "react-cookie";
import { redirect } from "react-router-dom";

import { refreshToken } from "./authAPI";
import { setErrorStatus, setErrorType } from "../redux/actions/errors";
import {getAPIServiceUrl} from "./authAPI"

const getHeaders = (token, tenant) => {
  const headers = new Headers();

  if (token) {
    headers.set("Authorization", `Bearer ${token}`);
  }

  if (tenant) {
    headers.set("tenant", `${tenant.id}`);
  }

  headers.set("Content-Type", "application/json");

  headers.set("Access-Control-Allow-Origin", "*");

  return headers;
};

const requestData = (params, dispatch) => {
  const cookies = new Cookies();

  let userInfo = cookies.get("user");
  let tenant = cookies.get("tenant");
  const url =
    (params.url.startsWith("/api/credentials") ||
      params.url.startsWith("/api/cittua") ||
      params.url.startsWith("/api/nwp")
      ? process.env.REACT_APP_WAC_SERVICE_URL ||
      process.env.REACT_APP_SERVICE_URL
      : process.env.REACT_APP_SERVICE_URL) + params.url;

  if (userInfo === undefined || tenant === undefined) {
    return redirect("/login");
  } else {
    return fetch(url, {
      method: params.method.toUpperCase(),
      headers: getHeaders(userInfo.access_token, tenant),
      ...params.options,
    })
      .then(async (response) => {
        if (response.status === 401 && params.retries > 0) {
          params.retries--;
          userInfo = await refreshToken(userInfo.refresh_token);
          if (userInfo.success) {
            cookies.set("user", userInfo, {
              path: "/",
              domain: window.location.hostname,
              maxAge: 604800,
            });
            return requestData(params);
          } else {
            dispatch(setErrorType(userInfo.type));
            dispatch(setErrorStatus(true));
            return userInfo;
          }
        } else {
          if (response.status === 204) {
            return {
              success: true,
              data: [],
              page_info: { total: 0 },
            };
          }
          return response.json();
        }
      })
      .catch((error) => {
        console.log(
          "There has been a problem with your fetch operation: " + error
        );
      });
  }
};

export const getMobileUserData = async (dispatch) => {
  const token = localStorage.getItem("access_token");

  if (!token) {
    console.error("Token de autorização ausente.");
    return Promise.reject("Token de autorização ausente.");
  }

  try {
    const response = await fetch(`${getAPIServiceUrl()}/mobile/get-user`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      throw new Error(`Erro na requisição: ${response.status}`);
    }

    const data = await response.json();
    console.log("Resposta da API:", data);
    return data;
  } catch (error) {
    console.error("Erro ao buscar dados do usuário:", error);
    throw error;
  }
};


export const updateMobileUserData = async (params, dispatch) => {
  const token = localStorage.getItem("access_token");

  if (!token) {
    console.error("Token de autorização ausente.");
    return Promise.reject("Token de autorização ausente.");
  }

  try {
    const response = await fetch(`${getAPIServiceUrl()}/mobile/update-user`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...params.form,
      }),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(
        `Erro na requisição: ${response.status} - ${
          errorData.message || "Erro desconhecido"
        }`
      );
    }

    const data = await response.json();
    console.log("Dados atualizados com sucesso:", data);
    return data;
  } catch (error) {
    console.error("Erro ao atualizar dados do usuário:", error);
    throw error;
  }
};


export const getActiveUser = (dispatch) => {
  const requestParams = {
    method: "post",
    url: `/api/cittua/users/get-user`,
    options: {},
    retries: 3,
  };

  return requestData(requestParams, dispatch).then((response) => response);
};

export const updateUserData = (params, dispatch) => {
  const options = {
    body: JSON.stringify({
      ...params.form,
    }),
  };

  const requestParams = {
    method: "post",
    url: `/api/cittua/users/user-update`,
    options: options,
    retries: 3,
  };

  return requestData(requestParams, dispatch);
}

export const getTableItems = (params, dispatch) => {
  let options = {};
  let stringSearch = "";
  let requestParams = {};

  if (params.requestType === "post") {
    const requestBody = {
      ...params.options,
      search: "",
    };

    const options = {
      body: JSON.stringify(requestBody),
    };

    requestParams = {
      method: "post",
      url: `/${params.requestUrl}`,
      options: options,
      retries: 3,
    };
  } else {
    if (params.options.search.length !== 0) {
      stringSearch = params.options.search
        .map((item) => JSON.stringify(item))
        .join(",");
    }
    let url = `/${params.requestUrl}?`;

    for (const key in params.options) {
      if (
        params.options.hasOwnProperty(key) &&
        key !== "search" &&
        params.options[key]
      ) {
        url += `&${key}=${encodeURIComponent(params.options[key])}`;
      }
    }

    url += `&search=[${encodeURIComponent(stringSearch)}]`;

    requestParams = {
      method: "get",
      url: url,
      options: options,
      retries: 3,
    };
  }

  return requestData(requestParams, dispatch).then((response) => response);
};

export const getTableItem = (params, dispatch) => {
  let options = {};

  const requestParams = {
    method: params.method,
    url: `/${params.url}/${params.id}`,
    options: options,
    retries: 3,
  };

  return requestData(requestParams, dispatch).then((response) => response);
};

export const createEditTableItem = (params, dispatch) => {
  let options = {
    body: JSON.stringify({
      ...params.form,
    }),
  };

  const requestParams = {
    method: params.method,
    url: `/${params.requestUrl}`,
    options: options,
    retries: 3,
  };

  return requestData(requestParams, dispatch).then((response) => response);
};

export const changeTableItemStatus = (params, dispatch) => {
  let options = {
    body: JSON.stringify({
      ...params.body,
    }),
  };

  const requestParams = {
    method: "post",
    url: `/${params.requestUrl}`,
    options: options,
    retries: 3,
  };

  return requestData(requestParams, dispatch).then((response) => response);
};

export const getSelectOptions = (url, dispatch) => {
  const requestParams = {
    method: "post",
    url: `/${url}`,
    options: {},
    retries: 3,
  };

  return requestData(requestParams, dispatch);
};

export const getRainReportLocationsOptions = (dispatch) => {
  const requestParams = {
    method: "get",
    url: "/api/rain-report/locations",
    options: {},
    retries: 3,
  };

  return requestData(requestParams, dispatch);
};

export const uploadAvatar = (avatar, dispatch) => {
  const options = {
    body: JSON.stringify({
      photo: avatar,
    }),
  };

  const requestParams = {
    method: "post",
    url: `/api/cittua/users/update-photo`,
    options: options,
    retries: 3,
  };

  return requestData(requestParams, dispatch);
};

export const removeAvatar = (dispatch) => {
  const requestParams = {
    method: "post",
    url: `/api/cittua/users/remove-photo`,
    options: {},
    retries: 3,
  };

  return requestData(requestParams, dispatch);
};

export const updateActiveUser = (params, dispatch) => {
  const options = {
    body: JSON.stringify({
      ...params.form,
    }),
  };

  const requestParams = {
    method: "post",
    url: `/api/cittua/users/user-update`,
    options: options,
    retries: 3,
  };

  return requestData(requestParams, dispatch);
};

export const getMapInitialOptions = (dispatch) => {
  const requestParams = {
    method: "get",
    url: `/api/nwp/config`,
    options: {},
    retries: 3,
  };

  return requestData(requestParams, dispatch).then((response) => response);
};

export const getMapLayers = (params) => {
  const url = `/api/nwp/layers?
	datetime=${params.datetime}
	&bouding_box=${params.boundingBox[0]}, ${params.boundingBox[1]}, ${params.boundingBox[2]}, ${params.boundingBox[3]}
	&preview_forecasts=${params.preview_forecasts}`;

  const requestParams = {
    method: "get",
    url: url,
    options: {},
    retries: 3,
  };

  return requestData(requestParams, params.dispatch).then(
    (response) => response
  );
};
export const deleteForecastItem = (id, dispatch) => {
  const requestParams = {
    method: "DELETE",
    url: `/api/weather/forecast/${id}`,
    options: {},
    retries: 3,
  };
  return requestData(requestParams, dispatch);
};

export const deleteAlertItem = (id, dispatch) => {
  const requestParams = {
    method: "DELETE",
    url: `/api/weather/alert/${id}`,
    options: {},
    retries: 3,
  };
  return requestData(requestParams, dispatch);
};

export const getDetails = (id) => {
  const summarized = {
    method: "get",
    url: `/api/v2/weather/alert/${id}/summary`,
  };
  return requestData(summarized);
};

export const getAlertTypes = (dispatch) => {
  const requestParams = {
    method: "get",
    url: `/api/alert-types`,
  };

  return requestData(requestParams, dispatch).then((response) => response);
};

export const getUserAlertsLocations = (dispatch) => {
  const requestParams = {
    method: "GET",
    url: `/api/cittua/users/alerts/location`,
    options: {},
    retries: 3,
  };

  return requestData(requestParams, dispatch)
}

export const createUserAlertLocation = (params, dispatch) => {
  const options = {
    body: JSON.stringify({
      ...params,
    }),
  };

  const requestParams = {
    method: "POST",
    url: `/api/cittua/users/alerts/location`,
    options: options,
    retries: 3,
  };

  return requestData(requestParams, dispatch);
}

export const deleteUserAlertLocation = (params, dispatch) => {
  const options = {
    body: JSON.stringify({
      ...params,
    }),
  };

  const requestParams = {
    method: "DELETE",
    url: `/api/cittua/users/alerts/location/`,
    options: options,
    retries: 3,
  };

  return requestData(requestParams, dispatch);
}

export const updateUserAlertsLocations = (params, dispatch) => {
  const options = {
    body: JSON.stringify({
      ...params,
    }),
  };

  const requestParams = {
    method: "PUT",
    url: `/api/cittua/users/alerts/location/`,
    options: options,
    retries: 3,
  };

  return requestData(requestParams, dispatch);
}