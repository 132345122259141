import { skyIconOptions, skyConditionOptions, windConditionOptions } from './constants'
import RadioGroupInput from '../../form_elements/RadioGroupInput'
import RangeSliderInput from '../../form_elements/RangeSliderInput'
import TextAreaInput from '../../form_elements/TextAreaInput'

function ForecastData({props}) {
	return (
		<>
			<div className='input-controls'>
				<RadioGroupInput
					params={{
						id: 'sky_condition',
						editDisabled: false,
						setIconField: true,
						iconOptions: skyIconOptions,
						options: skyConditionOptions,
						iconLabel: 'icon',
						formProps: props.formProps,
						formType: 'forecasts',
						validations: [
							{
								type: 'required',
							}
						]
					}}
				/>
			</div>
			<div className='input-controls'> 
				<RangeSliderInput 
					params={{
						id: 'temperature',
						editDisabled: false,
						scale: {
							min: -50,
							max: 50,
						},
						formProps: props.formProps,
						formType: 'forecasts',
						marks: [
							{
								value: -50,
								label: '-50°C',
							},
							{
								value: 50,
								label: '50°C',
							},
						 ],
						validations: [
							{
								type: 'required',
							}
						]
					}}
				/>
			</div>
			<div className='input-controls'>
				<RadioGroupInput 
					params={{
						id: 'wind_condition',
						editDisabled: false,
						setIconField: false,
						options: windConditionOptions,
						formProps: props.formProps,
						formType: 'forecasts',
						validations: [
							{
								type: 'required',
							}
						]
					}}
				/>
			</div>
			<div className='input-controls'>
				<RangeSliderInput 
					params={{
						id: 'humidity',
						editDisabled: false,
						scale: {
							min: 0,
							max: 100,
						},
						formProps: props.formProps,
						formType: 'forecasts',
						marks: [
							{
								value: 0,
								label: '0%',
							},
							{
								value: 100,
								label: '100%',
							},
						 ],
						validations: [
							{
								type: 'required',
							}
						]
					}}
				/>
			</div>
			<div>
				<TextAreaInput 
					params={{
						id: 'description',
						editDisabled: false,
						formProps: props.formProps,
						formType: 'forecasts',
						validations: [
							{
								type: 'required',
							}
						]
					}}
				/>
			</div>
		</>
	)
}

export default ForecastData