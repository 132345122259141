import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useNavigate } from "react-router-dom"
import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'

import { requestLogout } from '../utils/authAPI'
import IconSelector from './IconSelector'

function ErrorPage({params}) {
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const navigate = useNavigate()
	const [hasSecondaryBtn, setHasSecondaryBtn] = useState(false)

	const goHome = () => {
		if ((params.type === 'jsError') || (params.type === 'serverError')) {
			params.reset();
		}
		navigate('/');
	}

	const refreshPage = () => {
		params.reset();
		navigate(0);
	}

	const primaryBtnClick = () => {
		if ((params.type === 'pageNotFound') || (params.type === 'permissionsError')) {
			return goHome()
		} 
		
		if (params.type === 'formError') {
			return navigate(`/${params.formType}`)
		}

		if (params.type === 'detailsError') {
			return navigate(`../${params.detailsType}`)
		}

		if (params.type === 'get_user.failed') {
			params.reset()
			requestLogout(dispatch, navigate)
			return
		} 
		
		if (params.type === "token.failed" || params.type === "token.invalid" || params.type === "token.expired" || params.type === "token.notFound") {
			params.reset()
			requestLogout(dispatch, navigate)
			return
		}
		
		refreshPage()
	}

	useEffect(() => {
		if ((params.type === 'jsError') || (params.type === 'serverError')) {
			setHasSecondaryBtn(true)
		} else {
			setHasSecondaryBtn(false)
		}
	},[])

	function ErrorMessage({type}) {
		return (
			<div className='error-page__text-block'>
				<h1 className='error-page-title'>{t(`errorPage.message.${type}.title`)}</h1>
				<p className='error-page-text'>{t(`errorPage.message.${type}.text`)}</p>
			</div>
		)
	}

	return (
		<div className='c-error-page theme--light'>
			<div className='error-page-content'>
				<Avatar classes={{root: 'error-page__icon-wrapper'}}>
					<IconSelector svg='Warning' classname='icon--warning-big'/>
				</Avatar>
				<ErrorMessage type={params.type}/>
				<div className='c-controlBtns'>
					<Button onClick={primaryBtnClick} classes={{root: 'btn-primary btn-primary--large'}}>{t(`errorPage.primaryBtn.${params.type}`)}</Button>
					{hasSecondaryBtn && <Button classes={{root: 'btn-tertiary btn-tertiary--large btn-tertiary--margin-left'}} onClick={goHome}>{t(`errorPage.secondaryBtn.${params.type}`)}</Button>}
				</div>
			</div>
		</div>
	)
}

export default ErrorPage