import { useEffect, useState } from 'react'
import { useLocation, useNavigate, matchPath } from "react-router-dom"
import Button from '@mui/material/Button'

import IconSelector from '../IconSelector'

function PageTitleComponent({params}) {
	let location = useLocation()
	let navigate = useNavigate()
	const { pages } = params
	const [showTitle, setShowTitle] = useState(false)
	const [currentPage, setCurrentPage] = useState({})

	const backBtnAction = () => {
		navigate(currentPage.backBtnPath)
	}

	useEffect(() => {
		let pageMatch = pages.find((page) => {
			const match = matchPath({path: page.path, end: true}, location.pathname)
			return match !== null
		})

		if (pageMatch !== undefined) {
			setCurrentPage(pageMatch)
			setShowTitle(true)
		} else {
			setCurrentPage({})
			setShowTitle(false)
		}

	},[location])

	return (
		<div className='c-page-title'>
			{
				currentPage && currentPage.hasBackBtn &&
				<Button className='btn-page-title' onClick={backBtnAction}>
					<IconSelector svg='ArrowLeft'/>
				</Button>
			}
			<span className='page-title-text'>{showTitle ? currentPage.title : null}</span>
		</div>
	)
}

export default PageTitleComponent